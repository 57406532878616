import React, { useState } from 'react';

function I2i() {
  const [image, setImage] = useState(null);

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    console.log("Image uploaded for transformation:", event.target.files[0]);
    // 여기에 이미지 변환 로직 추가
  };

  return (
    <div className="text-center py-10">
      <h1 className="text-3xl text-custom-purple dark:text-white font-bold mb-4">준비중입니다</h1>
    </div>
  );
}

export default I2i;
