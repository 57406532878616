import React, { useEffect } from 'react';
import { useImageGeneration } from '../context/ImageGenerationContext';

const T2i = () => {
  const {
    prompt,
    setPrompt,
    negativePrompt,
    setNegativePrompt,
    image,
    loading,
    showOptions,
    setShowOptions,
    showAdvancedOptions,
    setShowAdvancedOptions,
    autoGenerator,
    setAutoGenerator,
    countdown,
    addPrePrompt,
    setAddPrePrompt,
    customWidth,
    setCustomWidth,
    customHeight,
    setCustomHeight,
    showCustomSizeSliders,
    leftCollapsed,
    setLeftCollapsed,
    rightStage,
    setRightStage,
    gridLayout,
    setGridLayout,
    exifImport,
    setExifImport,
    options,
    prePrompts,
    selectedBeforePrePrompt,
    setSelectedBeforePrePrompt,
    selectedAfterPrePrompt,
    setSelectedAfterPrePrompt,
    handleGenerate,
    handleOptionChange,
    handleImageSettingChange,
    determineImageSetting,
    handleDownloadZip,
    handleDeleteHistory,
    handleImageClick,
    toggleGridLayout,
    handleRightStageChange,
    imageHistory,
    vibeTransfers,
    addVibeTransfer,
    removeVibeTransfer,
    updateVibeTransfer,
  } = useImageGeneration();

  const [showVibeTransfer, setShowVibeTransfer] = React.useState(false);

  const buttonClass = "bg-gray-500 hover:bg-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-200 dark:text-gray-300 font-bold py-2 px-4 rounded";

  const sanitizeFilename = (text) => {
    const sanitized = text.replace(/[<>:"/\\|?*]/g, '_').substring(0, 50) || 'prompt';
    return sanitized.trim();
  };

  const iconStyle = {
    filter: 'invert(100%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(200%) contrast(100%)'
  };

  const confirmDeleteHistory = () => {
    if (window.confirm('Are you sure you want to delete the history?')) {
      handleDeleteHistory();
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "//t1.daumcdn.net/kas/static/ba.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (!leftCollapsed) {
      const adContainer = document.querySelector('.kakao-ad-container');
      if (adContainer) {
        adContainer.innerHTML = ''; // 기존 광고 내용 지우기
        const ins = document.createElement('ins');
        ins.className = 'kakao_ad_area';
        ins.style.display = 'none';
        ins.setAttribute('data-ad-unit', 'DAN-ae0QcXZS9tbMG4RX');
        ins.setAttribute('data-ad-width', '300');
        ins.setAttribute('data-ad-height', '250');
        adContainer.appendChild(ins);

        const script = document.createElement('script');
        script.src = "//t1.daumcdn.net/kas/static/ba.min.js";
        script.async = true;
        document.body.appendChild(script);
      }
    }
  }, [leftCollapsed]);

  return (
    <div className="flex flex-col md:flex-row h-screen bg-custom-white dark:bg-custom-black text-custom-purple dark:text-white p-4">
      {!leftCollapsed && (
        <div className="w-full md:w-1/4 p-4 border-custom-gray dark:border-custom-purple space-y-4 overflow-y-auto">
          <h2 className="text-lg font-bold">Prompt</h2>
          <textarea
            className="w-full h-24 p-2 mb-4 border border-gray-400 bg-gray-200 dark:border-custom-purple rounded text-black dark:text-black"
            placeholder="Enter your prompt here..."
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          ></textarea>
          <h2 className="text-lg font-bold">Negative Prompt</h2>
          <textarea
            className="w-full h-24 p-2 mb-4 border border-gray-400 bg-gray-200 dark:border-custom-purple rounded text-black dark:text-black"
            placeholder="Enter your negative prompt here..."
            value={negativePrompt}
            onChange={(e) => setNegativePrompt(e.target.value)}
          ></textarea>
          <button
            className={`w-full ${buttonClass} mt-4`}
            onClick={() => setShowOptions(!showOptions)}
          >
            {showOptions ? '▲ AI Settings' : '▼ AI Settings'}
          </button>
          {showOptions && (
            <div className="overflow-y-auto">
              <div className="mb-2">
                <label htmlFor="model" className="block">Model</label>
                <select
                  id="model"
                  value={options.model}
                  onChange={(e) => handleOptionChange('model', e.target.value)}
                  className="w-full bg-gray-200 text-black"
                >
                  <option value="nai-diffusion-3">nai-diffusion-3</option>
                  <option value="nai-diffusion-3-furry">nai-diffusion-3-furry</option>
                </select>
              </div>
              <div className="mb-2">
                <label htmlFor="sampler" className="block">Sampler</label>
                <select
                  id="sampler"
                  value={options.sampler}
                  onChange={(e) => handleOptionChange('sampler', e.target.value)}
                  className="w-full bg-gray-200 text-black"
                >
                  <option value="k_euler">Euler</option>
                  <option value="k_euler_ancestral">Euler Ancestral</option>
                  <option value="k_dpmpp_2m">DPM++ 2M</option>
                  <option value="k_dpmpp_2s_ancestral">DPM++ 2S Ancestral</option>
                  <option value="k_dpmpp_sde">DPM++ SDE</option>
                  <option value="ddim_v3">DDIM</option>
                </select>
              </div>
              <div className="mb-2">
                <label htmlFor="noise_schedule" className="block">Noise Schedule</label>
                <select
                  id="noise_schedule"
                  value={options.noise_schedule}
                  onChange={(e) => handleOptionChange('noise_schedule', e.target.value)}
                  className="w-full bg-gray-200 text-black"
                >
                  <option value="exponential">exponential</option>
                  <option value="native">native</option>
                  <option value="karras">karras</option>
                  <option value="polyexponential">polyexponential</option>
                </select>
              </div>
              <div className="mb-2 flex items-center">
                <label htmlFor="sm" className="mr-2">SMEA</label>
                <input
                  type="checkbox"
                  id="sm"
                  checked={options.sm}
                  onChange={(e) => handleOptionChange('sm', e.target.checked)}
                  className="mr-2"
                />
                <label htmlFor="sm_dyn" className="mr-2">DYN</label>
                <input
                  type="checkbox"
                  id="sm_dyn"
                  checked={options.sm_dyn}
                  onChange={(e) => handleOptionChange('sm_dyn', e.target.checked)}
                  disabled={!options.sm}
                  className="mr-2"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="image_setting" className="block">Image Setting</label>
                <select
                  id="image_setting"
                  value={determineImageSetting()}
                  onChange={(e) => handleImageSettingChange(e.target.value)}
                  className="w-full bg-gray-200 text-black"
                >
                  <option value="832x1216">Portrait (832x1216)</option>
                  <option value="1216x832">Landscape (1216x832)</option>
                  <option value="1024x1024">Square (1024x1024)</option>
                </select>
                {showOptions && showCustomSizeSliders && (
                  <>
                    <div className="mb-2">
                      <label htmlFor="customWidth" className="block">Width : {customWidth}px</label>
                      <input type="range" id="customWidth" value={customWidth} onChange={(e) => setCustomWidth(+e.target.value)}
                        className="w-full appearance-none bg-gray-200 h-2 rounded" min="1" max="1216" />
                    </div>
                    <div className="mb-2">
                      <label htmlFor="customHeight" className="block">Height : {customHeight}px</label>
                      <input type="range" id="customHeight" value={customHeight} onChange={(e) => setCustomHeight(+e.target.value)}
                        className="w-full appearance-none bg-gray-200 h-2 rounded" min="1" max="1216" />
                    </div>
                  </>
                )}
              </div>
              <div className="mb-2">
                <label htmlFor="steps" className="block">Steps : {options.steps}</label>
                <input type="range" id="steps" value={options.steps}
                  onChange={(e) => handleOptionChange('steps', +e.target.value)}
                  className="w-full" min="0" max="28" step="1" />
              </div>
              <div className="mb-2">
                <label htmlFor="scale" className="block">Scale : {options.scale}</label>
                <input type="range" id="scale" value={options.scale}
                  onChange={(e) => handleOptionChange('scale', +e.target.value)}
                  className="w-full" min="0" max="10" step="0.1" />
              </div>
              <div className="mb-2">
                <label htmlFor="cfg_rescale" className="block">Prompt Guidance Rescale : {options.cfg_rescale}</label>
                <input type="range" id="cfg_rescale" value={options.cfg_rescale}
                  onChange={(e) => handleOptionChange('cfg_rescale', +e.target.value)}
                  className="w-full" min="0" max="1" step="0.02" />
              </div>
              <div className="mb-2 flex flex-col">
                <label htmlFor="seed" className="block mb-1">Seed</label>
                <div className="flex items-center">
                  <input
                    type="text"
                    id="seed-text"
                    value={options.seed.toString()}
                    onChange={(e) => handleOptionChange('seed', Number(e.target.value))}
                    className="w-full bg-gray-200 text-black p-1 mr-2"
                    pattern="[0-9]*"
                  />
                  <input
                    type="range"
                    id="seed"
                    value={options.seed}
                    onChange={(e) => handleOptionChange('seed', +e.target.value)}
                    className="w-full"
                    min="-1"
                    max="9999999999"
                    step="1"
                  />
                </div>
              </div>
            </div>
          )}
          <button
            className={`w-full ${buttonClass} mt-4`}
            onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
          >
            {showAdvancedOptions ? '▲ Advanced Options' : '▼ Advanced Options'}
          </button>
          {showAdvancedOptions && (
            <div className="overflow-y-auto">
              <div className="mb-2 flex items-center">
                <label htmlFor="auto_generate" className="mr-2">Auto Generate</label>
                <div className="relative inline-block w-10 align-middle select-none transition duration-200 ease-in bg-gray-300 rounded-full">
                  <input
                    type="checkbox"
                    id="auto_generate"
                    checked={autoGenerator}
                    onChange={() => setAutoGenerator(!autoGenerator)}
                    className={`toggle-checkbox absolute left-1 top-1 block w-4 h-4 rounded-full border-1 appearance-none cursor-pointer ${autoGenerator ? 'translate-x-full bg-red-600' : 'translate-x-0 bg-white'}`}
                    style={{ transition: 'transform 0.2s ease-in-out' }}
                  />
                  <label htmlFor="auto_generate" className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                </div>
                <label htmlFor="auto_generate" className={`ml-2 font-bold ${autoGenerator ? 'text-red-600' : 'text-gray-200'}`}>{autoGenerator ? 'On' : 'Off'}</label>
              </div>
              <div className="mb-2 flex items-center">
                <label htmlFor="add_pre_prompt" className="mr-2">Add Pre-Prompt</label>
                <div className="relative inline-block w-10 align-middle select-none transition duration-200 ease-in bg-gray-300 rounded-full">
                  <input type="checkbox" id="add_pre_prompt" checked={addPrePrompt} onChange={() => setAddPrePrompt(!addPrePrompt)}
                    className={`toggle-checkbox absolute left-1 top-1 block w-4 h-4 rounded-full border-1 appearance-none cursor-pointer ${addPrePrompt ? 'translate-x-full bg-red-600' : 'translate-x-0 bg-white'}`}
                    style={{ transition: 'transform 0.2s ease-in-out' }} />
                  <label htmlFor="add_pre_prompt" className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                </div>
                <label htmlFor="add_pre_prompt" className={`ml-2 font-bold ${addPrePrompt ? 'text-red-600' : 'text-gray-200'}`}>{addPrePrompt ? 'On' : 'Off'}</label>
              </div>
              {addPrePrompt && (
                <>
                  <div className="mb-2">
                    <label htmlFor="before_pre_prompt" className="block">Before Pre-Prompt</label>
                    <select
                      id="before_pre_prompt"
                      value={selectedBeforePrePrompt || ''}
                      onChange={(e) => setSelectedBeforePrePrompt(e.target.value)}
                      className="w-full bg-gray-200 text-black"
                    >
                      <option value="">Select a Pre-Prompt</option>
                      {prePrompts.filter(p => p.position === 0).map(prePrompt => (
                        <option key={prePrompt.name} value={prePrompt.name}>
                          {prePrompt.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-2">
                    <label htmlFor="after_pre_prompt" className="block">After Pre-Prompt</label>
                    <select
                      id="after_pre_prompt"
                      value={selectedAfterPrePrompt || ''}
                      onChange={(e) => setSelectedAfterPrePrompt(e.target.value)}
                      className="w-full bg-gray-200 text-black"
                    >
                      <option value="">Select a Pre-Prompt</option>
                      {prePrompts.filter(p => p.position === 1).map(prePrompt => (
                        <option key={prePrompt.name} value={prePrompt.name}>
                          {prePrompt.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}
              <div className="mb-2 flex items-center">
                <label htmlFor="exif_import" className="mr-2">Exif Import</label>
                <div className="relative inline-block w-10 align-middle select-none transition duration-200 ease-in bg-gray-300 rounded-full">
                  <input
                    type="checkbox"
                    id="exif_import"
                    checked={exifImport}
                    onChange={() => setExifImport(!exifImport)}
                    className={`toggle-checkbox absolute left-1 top-1 block w-4 h-4 rounded-full border-1 appearance-none cursor-pointer ${exifImport ? 'translate-x-full bg-red-600' : 'translate-x-0 bg-white'}`}
                    style={{ transition: 'transform 0.2s ease-in-out' }}
                  />
                  <label htmlFor="exif_import" className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                </div>
                <label htmlFor="exif_import" className={`ml-2 font-bold ${exifImport ? 'text-red-600' : 'text-gray-200'}`}>{exifImport ? 'On' : 'Off'}</label>
              </div>
            </div>
          )}
          <button
            className={`w-full ${buttonClass} mt-4`}
            onClick={() => setShowVibeTransfer(!showVibeTransfer)}
          >
            {showVibeTransfer ? '▲ Vibe Transfer' : '▼ Vibe Transfer'}
          </button>
          {showVibeTransfer && (
            <div className="overflow-y-auto">
              {vibeTransfers.map((vibeTransfer, index) => (
                <div key={index} className="mb-4 p-4 border border-gray-400 rounded-lg flex">
                  <div className="relative flex-shrink-0 mr-4">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => updateVibeTransfer(index, 'image', URL.createObjectURL(e.target.files[0]))}
                      className="absolute inset-0 w-full h-full opacity-0 cursor-pointer z-10"
                    />
                    <div className="relative w-24 h-24 bg-gray-200 overflow-hidden flex items-center justify-center">
                      {vibeTransfer.image ? (
                        <img
                          src={vibeTransfer.image}
                          alt="Vibe Transfer"
                          className="absolute inset-0 w-full h-full object-contain"
                        />
                      ) : (
                        <span className="text-gray-500">+</span>
                      )}
                      <button
                        className="absolute top-0 right-0 bg-red-500 text-white p-1 z-20"
                        onClick={() => removeVibeTransfer(index)}
                      >
                        ✕
                      </button>
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="mb-2">
                      <label htmlFor={`info_extracted_${index}`} className="block">Information Extracted: {vibeTransfer.infoExtracted}</label>
                      <input
                        type="range"
                        id={`info_extracted_${index}`}
                        value={vibeTransfer.infoExtracted}
                        onChange={(e) => updateVibeTransfer(index, 'infoExtracted', +e.target.value)}
                        className="w-full"
                        min="0.01"
                        max="1"
                        step="0.01"
                      />
                    </div>
                    <div className="mb-2">
                      <label htmlFor={`ref_strength_${index}`} className="block">Reference Strength: {vibeTransfer.refStrength}</label>
                      <input
                        type="range"
                        id={`ref_strength_${index}`}
                        value={vibeTransfer.refStrength}
                        onChange={(e) => updateVibeTransfer(index, 'refStrength', +e.target.value)}
                        className="w-full"
                        min="0.01"
                        max="1"
                        step="0.01"
                      />
                    </div>
                  </div>
                </div>
              ))}
              <button
                className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
                onClick={addVibeTransfer}
              >
                +
              </button>
            </div>
          )}
          <div className="kakao-ad-container flex justify-center items-center">
            <ins className="kakao_ad_area" style={{ display: 'none' }}
              data-ad-unit="DAN-ae0QcXZS9tbMG4RX"
              data-ad-width="300"
              data-ad-height="250"></ins>
          </div>
        </div>
      )}
      <div className={`relative flex-1 p-4 flex flex-col justify-center items-center border border-custom-gray dark:border-custom-purple rounded-lg ${leftCollapsed ? 'w-full md:w-3/4' : 'w-full md:w-1/2'}`}>
        <div className="relative w-full h-full max-w-full max-h-full flex justify-center items-center">
          {image ? (
            <div className="relative group w-full h-full flex justify-center items-center">
              <img
                src={image}
                alt="Generated"
                className="max-h-full max-w-full object-contain mx-auto"
              />
              <button
                className="absolute top-4 right-4 bg-gray-500 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded hidden group-hover:block"
                onClick={() => {
                  const link = document.createElement('a');
                  link.href = image;
                  link.download = `${sanitizeFilename(prompt)}.png`;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
              >
                <img src="/img/download.webp" alt="Download" className="w-6 h-6 mx-auto" style={iconStyle} />
              </button>
            </div>
          ) : (
            <p className="text-gray-500 text-center">No image generated yet.</p>
          )}
        </div>
        <button
          className={`fixed bottom-4 left-1/2 transform -translate-x-1/2 ${autoGenerator ? 'bg-green-500' : 'bg-custom-purple hover:bg-purple-700'} dark:hover:bg-purple-500 text-white font-bold py-3 px-6 rounded`}
          onClick={handleGenerate}
          disabled={loading}
          style={{ zIndex: 1000 }}
        >
          {loading ? <img src="/img/loading.webp" alt="loading" className="w-6 h-6 mx-auto" style={iconStyle} /> : autoGenerator ? `Auto Generating (${countdown}s)` : 'Generate'}
        </button>
        <button
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-500 hover:bg-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600 text-white font-bold py-2 px-2 rounded"
          onClick={() => setLeftCollapsed(!leftCollapsed)}
        >
          {leftCollapsed ? (
            <>
              <span className="block md:hidden">▼</span>
              <span className="hidden md:block">▶</span>
            </>
          ) : (
            <>
              <span className="block md:hidden">▲</span>
              <span className="hidden md:block">◀</span>
            </>
          )}
        </button>
        <button
          className={`absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-500 hover:bg-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600 text-white font-bold py-2 px-2 rounded`}
          onClick={handleRightStageChange}
        >
          {rightStage === 0 ? (
            <>
              <span className="block md:hidden">▼</span>
              <span className="hidden md:block">◀</span>
            </>
          ) : rightStage === 1 ? (
            <>
              <span className="block md:hidden">▼</span>
              <span className="hidden md:block">◀</span>
            </>
          ) : (
            <>
              <span className="block md:hidden">▲</span>
              <span className="hidden md:block">▶</span>
            </>
          )}
        </button>
      </div>
      {rightStage > 0 && (
        <div className={`w-full md:w-1/${rightStage === 1 ? '4' : '2'} p-4 overflow-auto rounded-lg`}>
          <h2 className="text-lg font-bold mb-4">History</h2>
          <div className="flex justify-between mb-4 space-x-2">
            <button
              className="flex items-center justify-center flex-1 bg-custom-purple hover:bg-purple-700 dark:hover:bg-purple-500 text-white font-bold py-2 px-4 rounded"
              onClick={handleDownloadZip}
            >
              <img src="img/downloadzip.webp" alt="Download Zip" className="w-6 h-6" style={iconStyle} />
            </button>
            <button
              className={`flex items-center justify-center flex-1 bg-gray-500 hover:bg-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600 text-white font-bold py-2 px-4 rounded`}
              onClick={toggleGridLayout}
            >
              <img src="img/history.webp" alt="Grid Layout" className="w-6 h-6" style={iconStyle} />
            </button>
            <button
              className="flex items-center justify-center flex-1 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              onClick={confirmDeleteHistory}
            >
              <img src="img/removehistory.webp" alt="Delete" className="w-6 h-6" style={iconStyle} />
            </button>
          </div>
          <div className="mt-4 border border-custom-gray dark:border-custom-purple p-2 rounded-lg">
            <div className="history-grid" style={{ display: 'grid', gridTemplateColumns: `repeat(${gridLayout}, 1fr)`, gap: '1rem' }}>
              {imageHistory.map((imgData, index) => (
                <div key={index} className="border border-custom-gray dark:border-custom-purple rounded-lg overflow-hidden relative" onClick={() => handleImageClick(imgData)}>
                  <img src={imgData.url} alt={`Image ${index + 1}`} className="w-full h-full object-cover" style={{ aspectRatio: 1 }} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default T2i;
