import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { openDB, saveImage, getImage, deleteImage, STORE_NAME } from './indexedDB'; // Import IndexedDB utility

const ImageGenerationContext = createContext();

export const useImageGeneration = () => useContext(ImageGenerationContext);

const base64ToBlob = (base64Data) => {
  const byteCharacters = atob(base64Data.split(',')[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: 'image/png' });
  return blob;
};

const getBlobUrlFromIndexedDB = async (id) => {
  try {
    const base64Data = await getImage(id);
    if (base64Data) {
      const blob = base64ToBlob(base64Data);
      const url = URL.createObjectURL(blob);
      return url;
    } else {
      throw new Error('Image not found in IndexedDB');
    }
  } catch (error) {
    console.error('Error fetching image from IndexedDB:', error);
    return null;
  }
};

const resizeBase64Img = (base64, width, height) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = base64;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = width;
      canvas.height = height;

      const ratio = Math.min(width / img.width, height / img.height);
      const x = (width - img.width * ratio) / 2;
      const y = (height - img.height * ratio) / 2;

      ctx.fillStyle = 'black';
      ctx.fillRect(0, 0, width, height);
      ctx.drawImage(img, 0, 0, img.width, img.height, x, y, img.width * ratio, img.height * ratio);

      resolve(canvas.toDataURL('image/png'));
    };
  });
};

export const ImageGenerationProvider = ({ children }) => {
  const [prompt, setPrompt] = useState('');
  const [negativePrompt, setNegativePrompt] = useState('');
  const [image, setImage] = useState('img/denden.webp');
  const [loading, setLoading] = useState(false);
  const [imageHistory, setImageHistory] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [autoGenerator, setAutoGenerator] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const countdownRef = useRef(null);
  const [addPrePrompt, setAddPrePrompt] = useState(false);
  const [customWidth, setCustomWidth] = useState(1216);
  const [customHeight, setCustomHeight] = useState(832);
  const [showCustomSizeSliders, setShowCustomSizeSliders] = useState(false);
  const [leftCollapsed, setLeftCollapsed] = useState(false);
  const [rightStage, setRightStage] = useState(0);
  const [gridLayout, setGridLayout] = useState(1);
  const [exifImport, setExifImport] = useState(false);
  const [playAlertOnCompletion, setPlayAlertOnCompletion] = useState(false);
  const [autoSaveZip, setAutoSaveZip] = useState(false);
  const [options, setOptions] = useState({
    model: "nai-diffusion-3",
    width: 832,
    height: 1216,
    scale: 5,
    sampler: "k_euler",
    steps: 28,
    sm: false,
    sm_dyn: false,
    uncond_scale: 1,
    cfg_rescale: 0,
    noise_schedule: "exponential",
    seed: -1
  });
  const [prePrompts, setPrePrompts] = useState([]);
  const [selectedBeforePrePrompt, setSelectedBeforePrePrompt] = useState(null);
  const [selectedAfterPrePrompt, setSelectedAfterPrePrompt] = useState(null);
  const [vibeTransfers, setVibeTransfers] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [presets, setPresets] = useState([]);
  const [generating, setGenerating] = useState(false);
  const [presetGroups, setPresetGroups] = useState([]);
  const [currentGroupName, setCurrentGroupName] = useState('New preset group');
  // Dictionary to store unique images
  const [imageDictionary, setImageDictionary] = useState({});

  useEffect(() => {
    const savedPrompt = localStorage.getItem('prompt');
    const savedNegativePrompt = localStorage.getItem('negativePrompt');
    const savedOptions = localStorage.getItem('options');
    const savedAlert = localStorage.getItem('playAlertOnCompletion');
    const savedPrePrompts = localStorage.getItem('prePrompts');
    const savedPresets = localStorage.getItem('presets');
    const savedAutoSaveZip = localStorage.getItem('autoSaveZip');
    const savedPresetGroups = localStorage.getItem('presetGroups');

    if (savedPrompt) setPrompt(savedPrompt);
    if (savedNegativePrompt) setNegativePrompt(savedNegativePrompt);
    if (savedOptions) setOptions(JSON.parse(savedOptions));
    if (savedAlert !== null) setPlayAlertOnCompletion(savedAlert === 'true');
    if (savedPrePrompts) setPrePrompts(JSON.parse(savedPrePrompts));
    if (savedPresets) {
      const parsedPresets = JSON.parse(savedPresets);
      const migratedPresets = parsedPresets.map(migratePreset);
      setPresets(migratedPresets);
    }
    if (savedAutoSaveZip !== null) setAutoSaveZip(savedAutoSaveZip === 'true');
    if (savedPresetGroups) {
      setPresetGroups(JSON.parse(savedPresetGroups));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('prompt', prompt);
    localStorage.setItem('negativePrompt', negativePrompt);
    localStorage.setItem('options', JSON.stringify(options));
  }, [prompt, negativePrompt, options]);

  useEffect(() => {
    localStorage.setItem('playAlertOnCompletion', playAlertOnCompletion.toString());
  }, [playAlertOnCompletion]);

  useEffect(() => {
    localStorage.setItem('autoSaveZip', autoSaveZip.toString());
  }, [autoSaveZip]);

  useEffect(() => {
    if (autoGenerator && imageLoaded) {
      const autoGenerateDelay = parseInt(localStorage.getItem('autoGenerateDelay')) || 30;
      setCountdown(autoGenerateDelay);

      countdownRef.current = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            handleGenerate();
            return autoGenerateDelay;
          }
          return prev - 1;
        });
      }, 1000);

      return () => {
        clearInterval(countdownRef.current);
      };
    } else {
      clearInterval(countdownRef.current);
    }
  }, [autoGenerator, playAlertOnCompletion, imageLoaded]);

  useEffect(() => {
    // Open IndexedDB when the component mounts
    openDB().catch(console.error);
  }, []);

  const handleGenerate = async () => {
    const apiKey = localStorage.getItem('apiKey');
    const requestUrl = localStorage.getItem('requestUrl');
    if (!apiKey || !requestUrl) {
      console.error("API key or request URL is missing");
      return;
    }

    setLoading(true);

    let currentPrompt = prompt;
    let currentNegativePrompt = negativePrompt;
    let currentOptions = { ...options };

    if (addPrePrompt) {
      const beforePrePrompt = prePrompts.find(p => p.name === selectedBeforePrePrompt)?.text || '';
      const afterPrePrompt = prePrompts.find(p => p.name === selectedAfterPrePrompt)?.text || '';
      currentPrompt = `${beforePrePrompt ? beforePrePrompt + ', ' : ''}${currentPrompt}${afterPrePrompt ? ', ' + afterPrePrompt : ''}`;
    }

    const reference_image_multiple = [];
    const reference_information_extracted_multiple = [];
    const reference_strength_multiple = [];
    let add_original_image = false;

    for (const vibeTransfer of vibeTransfers) {
      if (vibeTransfer.image) {
        const base64Image = await convertUrlToBase64(vibeTransfer.image);
        const resizedBase64Image = await resizeBase64Img(base64Image, 448, 448);
        reference_image_multiple.push(resizedBase64Image.replace(/^data:image\/(png|jpeg);base64,/, '')); // Remove prefix
        reference_information_extracted_multiple.push(vibeTransfer.infoExtracted);
        reference_strength_multiple.push(vibeTransfer.refStrength);
        add_original_image = true;
      }
    }

    try {
      const response = await axios.post(
        'https://dendenai.xyz/generate-image',
        {
          input: currentPrompt,
          negative_prompt: currentNegativePrompt,
          model: currentOptions.model,
          sampler: currentOptions.sampler,
          noise_schedule: currentOptions.noise_schedule,
          sm: currentOptions.sm,
          sm_dyn: currentOptions.sm_dyn,
          seed: currentOptions.seed,
          width: currentOptions.width,
          height: currentOptions.height,
          scale: currentOptions.scale,
          cfg_rescale: currentOptions.cfg_rescale,
          reference_image_multiple,
          reference_information_extracted_multiple,
          reference_strength_multiple,
          add_original_image,
          requestUrl: requestUrl
        },
        {
          headers: {
            'Authorization': `Bearer ${apiKey}`
          },
          responseType: 'blob'
        }
      );

      if (response.status === 200) {
        const imageUrl = URL.createObjectURL(new Blob([response.data], { type: 'image/png' }));
        const imageData = {
          id: `image_${new Date().getTime()}`,
          url: imageUrl,
          prompt: currentPrompt,
          negativePrompt: currentNegativePrompt,
          options: currentOptions
        };
        setImage(imageUrl);
        setImageHistory(prevHistory => [...prevHistory, imageData]);
        setImageLoaded(false);
        if (playAlertOnCompletion) playCompletionSound();
        if (autoSaveZip) handleDownloadZip();
      } else {
        console.error("Image generation failed", response.data);
      }
    } catch (error) {
      console.error("Error generating image", error);
    }
    setLoading(false);
  };

  const handlePresetGenerate = async (index, isLastPreset = false) => {
    const apiKey = localStorage.getItem('apiKey');
    const requestUrl = localStorage.getItem('requestUrl');
    const presetGenerateDelay = parseInt(localStorage.getItem('presetGenerateDelay')) || 30;

    if (!apiKey || !requestUrl) {
      console.error("API key or request URL is missing");
      return;
    }

    setLoading(true);

    const preset = presets[index];
    if (!preset) {
      console.error("Preset not found");
      setLoading(false);
      return;
    }

    let currentPrompt = preset.presetPrompt;
    let currentNegativePrompt = negativePrompt;
    let currentOptions = { ...options };

    const beforePrePrompt = prePrompts.find(p => p.name === preset.beforePrePrompt)?.text || '';
    const afterPrePrompt = prePrompts.find(p => p.name === preset.afterPrePrompt)?.text || '';
    currentPrompt = `${beforePrePrompt ? beforePrePrompt + ', ' : ''}${prompt ? prompt + ', ' : ''}${currentPrompt}${afterPrePrompt ? ', ' + afterPrePrompt : ''}`;

    const reference_image_multiple = [];
    const reference_information_extracted_multiple = [];
    const reference_strength_multiple = [];
    let add_original_image = false;

    console.log("Starting Vibe Transfer Processing");

    for (const vibeTransfer of vibeTransfers) {
      if (vibeTransfer.image) {
        try {
          console.log("Processing Vibe Transfer:", vibeTransfer);
          const base64Image = await convertUrlToBase64(vibeTransfer.image);
          const resizedBase64Image = await resizeBase64Img(base64Image, 448, 448);
          reference_image_multiple.push(resizedBase64Image.split(',')[1]); // Remove prefix
          reference_information_extracted_multiple.push(vibeTransfer.infoExtracted);
          reference_strength_multiple.push(vibeTransfer.refStrength);
          add_original_image = true;
        } catch (error) {
          console.error("Error processing Vibe Transfer:", error);
        }
      }
    }

    console.log("Vibe Transfers processed:", reference_image_multiple);

    // Add preset-specific Vibe Transfers
    for (const vibeTransfer of Array.isArray(preset.vibeTransfers) ? preset.vibeTransfers : []) {
      if (vibeTransfer.image) {
        try {
          console.log("Processing Preset Vibe Transfer:", vibeTransfer);
          const base64Image = await getImage(vibeTransfer.image); // Read from IndexedDB
          console.log("Retrieved base64 image from IndexedDB:", base64Image);
          const resizedBase64Image = await resizeBase64Img(base64Image, 448, 448);
          reference_image_multiple.push(resizedBase64Image.split(',')[1]); // Remove prefix
          reference_information_extracted_multiple.push(vibeTransfer.infoExtracted);
          reference_strength_multiple.push(vibeTransfer.refStrength);
          add_original_image = true;
        } catch (error) {
          console.error("Error processing Preset Vibe Transfer:", error);
        }
      }
    }

    console.log("All Vibe Transfers processed:", reference_image_multiple);

    try {
      const response = await axios.post(
        'https://dendenai.xyz/generate-image',
        {
          input: currentPrompt,
          negative_prompt: currentNegativePrompt,
          model: currentOptions.model,
          sampler: currentOptions.sampler,
          noise_schedule: currentOptions.noise_schedule,
          sm: currentOptions.sm,
          sm_dyn: currentOptions.sm_dyn,
          seed: currentOptions.seed,
          width: currentOptions.width,
          height: currentOptions.height,
          scale: currentOptions.scale,
          cfg_rescale: currentOptions.cfg_rescale,
          reference_image_multiple,
          reference_information_extracted_multiple,
          reference_strength_multiple,
          add_original_image,
          requestUrl: requestUrl
        },
        {
          headers: {
            'Authorization': `Bearer ${apiKey}`
          },
          responseType: 'blob'
        }
      );

      console.log("Response status:", response.status);
      console.log("Response data:", response.data);

      if (response.status === 200) {
        const imageUrl = URL.createObjectURL(new Blob([response.data], { type: 'image/png' }));
        console.log("Generated imageUrl:", imageUrl); // 로그 추가

        const updatedPresets = [...presets];
        updatedPresets[index].image = imageUrl;
        setPresets(updatedPresets);

        // Add the generated image to the image history
        const imageData = {
          id: `image_${new Date().getTime()}`,
          url: imageUrl,
          prompt: currentPrompt,
          negativePrompt: currentNegativePrompt,
          options: currentOptions
        };
        setImage(imageUrl); // Set the preview image
        setImageHistory(prevHistory => [...prevHistory, imageData]);

        setImageLoaded(false);
        if (playAlertOnCompletion) playCompletionSound();
        if (autoSaveZip && isLastPreset) handleDownloadZip();
      } else {
        console.error("Image generation failed", response.data);
      }
    } catch (error) {
      console.error("Error generating image", error);
    }

    setLoading(false);

    if (index < presets.length - 1) {
      await new Promise(resolve => setTimeout(resolve, presetGenerateDelay * 1000));
    }
  };

  const handleGenerateSelectedPresets = async (selectedPresets) => {
    setGenerating(true);
    for (let i = 0; i < selectedPresets.length; i++) {
      const presetIndex = presets.findIndex(p => p.id === selectedPresets[i]);
      await handlePresetGenerate(presetIndex, i === selectedPresets.length - 1);
    }
    setGenerating(false);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const convertUrlToBase64 = async (imageUrl) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  };

  const ensureBase64Prefix = (image) => {
    if (image && !image.startsWith('data:image/') && !image.startsWith('blob:')) {
      return `data:image/png;base64,${image}`;
    }
    return image;
  };

  const playCompletionSound = () => {
    const audio = new Audio('/sounds/completion.mp3');
    audio.play();
  };

  const handleOptionChange = (name, value) => {
    if (name === 'seed') {
      const numericValue = Number(value);
      const maxSeedValue = 9999999999;
      const adjustedValue = numericValue > maxSeedValue ? maxSeedValue : numericValue;
      setOptions(prev => ({ ...prev, [name]: adjustedValue }));
    } else {
      setOptions(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleImageSettingChange = (setting) => {
    if (setting === "Custom") {
      setShowCustomSizeSliders(true);
      setCustomWidth(options.width);
      setCustomHeight(options.height);
    } else {
      setShowCustomSizeSliders(false);
      const [newWidth, newHeight] = setting.split('x').map(Number);
      setOptions({ ...options, width: newWidth, height: newHeight });
    }
  };

  const determineImageSetting = () => {
    const standardSettings = ["832x1216", "1216x832", "1024x1024"];
    const currentSetting = `${options.width}x${options.height}`;
    return standardSettings.includes(currentSetting) ? currentSetting : "Custom";
  };

  const handleDownloadZip = async () => {
    const zip = new JSZip();
    const imgFolder = zip.folder("images");
    let count = 0;

    for (let i = 0; i < imageHistory.length; i++) {
      const img = imageHistory[i];
      const filename = `image${i + 1}.png`;
      const response = await fetch(img.url);
      const blob = await response.blob();
      imgFolder.file(filename, blob, { binary: true });
      count++;

      if (count === imageHistory.length) {
        const content = await zip.generateAsync({ type: "blob" });
        saveAs(content, "images.zip");
      }
    }
  };

  const handleDownloadPresetZip = async () => {
    const zip = new JSZip();
    const imgFolder = zip.folder("presets");
    let count = 0;

    for (let i = 0; i < presets.length; i++) {
      const preset = presets[i];
      if (preset.image) {
        const filename = `${preset.name || 'preset'}.png`;
        const response = await fetch(preset.image);
        const blob = await response.blob();
        imgFolder.file(filename, blob, { binary: true });
        count++;
      }
    }

    if (count > 0) {
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, "presets.zip");
    } else {
      console.error("No images to download");
    }
  };

  const handleDeleteHistory = () => {
    setImageHistory([]);
  };

  const handleImageClick = (imageData) => {
    setImage(imageData.url);
    setSelectedImage(imageData.url);
    if (exifImport) {
      setPrompt(imageData.prompt);
      setNegativePrompt(imageData.negativePrompt);
      setOptions(imageData.options);
    }
  };

  const toggleGridLayout = () => {
    setGridLayout((prevLayout) => {
      if (prevLayout === 1) return 2;
      if (prevLayout === 2) return 4;
      return 1;
    });
  };

  const handleRightStageChange = () => {
    setRightStage((prevStage) => (prevStage + 1) % 3);
  };

  const addVibeTransfer = (vibeTransfer) => {
    setVibeTransfers((prevTransfers) => [
      ...prevTransfers,
      vibeTransfer
    ]);
  };

  const removeVibeTransfer = async (index) => {
    const imageKey = vibeTransfers[index].image;
    setVibeTransfers((prevTransfers) => prevTransfers.filter((_, i) => i !== index));
    await deleteImage(imageKey);
  };

  const updateVibeTransfer = (index, field, value) => {
    setVibeTransfers((prevTransfers) => {
      const updatedTransfers = [...prevTransfers];
      updatedTransfers[index] = { ...updatedTransfers[index], [field]: value };
      return updatedTransfers;
    });
  };

  const addPreset = () => {
    setPresets((prevPresets) => {
      const newPreset = migratePreset({
        id: prevPresets.length + 1,
        name: '',
        beforePrePrompt: '',
        presetPrompt: '',
        afterPrePrompt: '',
        image: null,
        showVibeTransfer: false,
        vibeTransfers: []
      });
      const updatedPresets = [...prevPresets, newPreset];
      localStorage.setItem('presets', JSON.stringify(updatedPresets));
      return updatedPresets;
    });
  };

  const migratePreset = (preset) => {
    return {
      id: preset.id || `preset_${new Date().getTime()}`,
      name: preset.name || '',
      beforePrePrompt: preset.beforePrePrompt || '',
      presetPrompt: preset.presetPrompt || '',
      afterPrePrompt: preset.afterPrePrompt || '',
      image: preset.image || null,
      showVibeTransfer: preset.hasOwnProperty('showVibeTransfer') ? preset.showVibeTransfer : false,
      vibeTransfers: Array.isArray(preset.vibeTransfers) ? preset.vibeTransfers : []
    };
  };
  
  const updatePreset = async (index, field, value) => {
    if (field === 'vibeTransfers') {
      value = await Promise.all(value.map(async (vibeTransfer) => {
        if (typeof vibeTransfer.image === 'string' && vibeTransfer.image.startsWith('data:')) {
          vibeTransfer.image = await saveImageToIndexedDB(vibeTransfer.image);
        }
        return vibeTransfer;
      }));
    }
    setPresets((prevPresets) => {
      const updatedPresets = [...prevPresets];
      updatedPresets[index] = { ...updatedPresets[index], [field]: value };
      localStorage.setItem('presets', JSON.stringify(updatedPresets));
      return updatedPresets;
    });
  };

  const removePreset = async (index) => {
    const preset = presets[index];
    const imageKey = preset.imageKey;

    // Delete Vibe Transfer images associated with this preset
    for (const vibeTransfer of preset.vibeTransfers) {
      await deleteImage(vibeTransfer.image);
    }

    // Delete the preset image if exists
    if (imageKey) {
      await deleteImage(imageKey);
    }

    setPresets((prevPresets) => {
      const updatedPresets = prevPresets.filter((_, i) => i !== index);
      localStorage.setItem('presets', JSON.stringify(updatedPresets));
      return updatedPresets;
    });
  };

  const removeVibeTransferFromPreset = async (presetIndex, transferIndex) => {
    const updatedTransfers = [...presets[presetIndex].vibeTransfers];
    const imageKey = updatedTransfers[transferIndex].image;

    if (imageKey) {
      await deleteImage(imageKey);
    }

    updatedTransfers.splice(transferIndex, 1);
    updatePreset(presetIndex, 'vibeTransfers', updatedTransfers);
  };

  const importPresets = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const importedData = JSON.parse(e.target.result);

        // Migrate prePrompts if necessary
        const importedPrePrompts = importedData.prePrompts || [];
        const uniquePrePrompts = importedPrePrompts.reduce((acc, current) => {
          let name = current.name;
          let count = 1;
          while (acc.find(p => p.name === name)) {
            name = `${current.name} (${count})`;
            count++;
          }
          acc.push({ ...current, name });
          return acc;
        }, []);

        setPrePrompts(uniquePrePrompts);

        // Get all existing images from IndexedDB
        const allImages = await getAllImages();

        // Migrate presets to the latest format
        const importedPresets = await Promise.all(
          (importedData.presets || []).map(async (preset, index) => {
            const migratedPreset = migratePreset(preset);
            const vibeTransfers = migratedPreset.vibeTransfers || [];
            const decodedVibeTransfers = await Promise.all(
              vibeTransfers.map(async (vibe) => {
                const imageData = importedData.images[vibe.image];
                const imageKey = await saveImageToIndexedDB(imageData);
                return { ...vibe, image: imageKey };
              })
            );

            return { ...migratedPreset, id: index + 1, vibeTransfers: decodedVibeTransfers };
          })
        );

        setPresets(importedPresets);
        localStorage.setItem('prePrompts', JSON.stringify(uniquePrePrompts));
        localStorage.setItem('presets', JSON.stringify(importedPresets));
      };
      reader.readAsText(file);
    }
  };

  const exportPresets = async () => {
    const exportData = await Promise.all(
      presets.map(async (preset) => {
        const vibeTransfers = await Promise.all(
          preset.vibeTransfers.map(async (vibe) => {
            const base64Image = await getImage(vibe.image);
            return { ...vibe, image: base64Image };
          })
        );
        const { image, ...rest } = preset;
        return { ...rest, vibeTransfers };
      })
    );

    const uniqueImages = {};
    const presetsWithImageKeys = exportData.map((preset) => {
      const newVibeTransfers = preset.vibeTransfers.map((vibeTransfer) => {
        const imageKey = Object.keys(uniqueImages).find(key => uniqueImages[key] === vibeTransfer.image);
        if (!imageKey) {
          const newKey = `image_${Object.keys(uniqueImages).length + 1}`;
          uniqueImages[newKey] = vibeTransfer.image;
          return { ...vibeTransfer, image: newKey };
        }
        return { ...vibeTransfer, image: imageKey };
      });
      return { ...preset, vibeTransfers: newVibeTransfers };
    });

    const activePrePrompts = prePrompts.filter(prePrompt => 
      presetsWithImageKeys.some(preset => 
        preset.beforePrePrompt === prePrompt.name || preset.afterPrePrompt === prePrompt.name
      )
    );

    const blob = new Blob([JSON.stringify({ prePrompts: activePrePrompts, presets: presetsWithImageKeys, images: uniqueImages }, null, 2)], { type: 'application/json' });
    saveAs(blob, 'presets.json');
  };

  const saveImageToIndexedDB = async (base64Image) => {
    const existingKey = await findExistingImage(base64Image);
    if (existingKey) {
      return existingKey;
    } else {
      const imageKey = `image_${new Date().getTime()}`;
      await saveImage(imageKey, base64Image);
      return imageKey;
    }
  };

  const findExistingImage = async (base64Image) => {
    const allImages = await getAllImages();
    for (const [key, value] of Object.entries(allImages)) {
      if (value === base64Image) {
        return key;
      }
    }
    return null;
  };

  const getAllImages = async () => {
    const db = await openDB();
    return new Promise((resolve, reject) => {
      const tx = db.transaction(STORE_NAME, 'readonly');
      const store = tx.objectStore(STORE_NAME);
      const request = store.getAll();
      request.onsuccess = (event) => {
        const allImages = event.target.result;
        const images = {};
        if (Array.isArray(allImages)) {
          allImages.forEach((image) => {
            images[image.id] = image.imageData;
          });
          resolve(images);
        } else {
          reject(new Error("Unexpected data format from IndexedDB"));
        }
      };
      request.onerror = (event) => {
        reject(event.target.errorCode);
      };
    });
  };

  return (
    <ImageGenerationContext.Provider
      value={{
        prompt,
        setPrompt,
        negativePrompt,
        setNegativePrompt,
        image,
        setImage,
        loading,
        setLoading,
        imageHistory,
        setImageHistory,
        showOptions,
        setShowOptions,
        showAdvancedOptions,
        setShowAdvancedOptions,
        autoGenerator,
        setAutoGenerator,
        countdown,
        setCountdown,
        countdownRef,
        addPrePrompt,
        setAddPrePrompt,
        customWidth,
        setCustomWidth,
        customHeight,
        setCustomHeight,
        showCustomSizeSliders,
        setShowCustomSizeSliders,
        leftCollapsed,
        setLeftCollapsed,
        rightStage,
        setRightStage,
        gridLayout,
        setGridLayout,
        exifImport,
        setExifImport,
        playAlertOnCompletion,
        setPlayAlertOnCompletion,
        options,
        setOptions,
        prePrompts,
        setPrePrompts,
        selectedBeforePrePrompt,
        setSelectedBeforePrePrompt,
        selectedAfterPrePrompt,
        setSelectedAfterPrePrompt,
        handleGenerate,
        playCompletionSound,
        handleOptionChange,
        handleImageSettingChange,
        determineImageSetting,
        handleDownloadZip,
        handleDeleteHistory,
        handleImageClick,
        toggleGridLayout,
        handleRightStageChange,
        vibeTransfers,
        addVibeTransfer,
        removeVibeTransfer,
        updateVibeTransfer,
        selectedImage,
        setSelectedImage,
        setImageLoaded,
        handlePresetGenerate,
        handleGenerateSelectedPresets,
        handleDownloadPresetZip,
        presets,
        setPresets,
        addPreset,
        updatePreset,
        removePreset,
        removeVibeTransferFromPreset,
        importPresets,
        exportPresets,
        autoSaveZip,
        setAutoSaveZip,
        generating,
        setGenerating,
        convertToBase64,
        ensureBase64Prefix,
        imageDictionary,
        setImageDictionary,
        saveImageToIndexedDB,
        getBlobUrlFromIndexedDB,
        presetGroups,
        setPresetGroups,
        currentGroupName,
        setCurrentGroupName,
      }}
    >
      {children}
      <img
        src={image}
        onLoad={() => setImageLoaded(true)}
        alt="Generated"
        style={{ display: 'none' }}
      />
    </ImageGenerationContext.Provider>
  );
};
