import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "./context/ThemeContext";
import Nav from "./pages/Nav";
import Home from "./pages/Home";
import T2i from "./pages/T2i";
import I2i from "./pages/I2i";
import P2i from "./pages/P2i";
import ImageInformation from "./pages/ImageInformation";
import Settings from "./pages/Settings";

function App() {
  return (
    <Router>
      <ThemeProvider>
        <div className="min-h-screen bg-custom-white text-custom-purple dark:bg-custom-black dark:text-white flex flex-col">
          {" "}
          {/* Updated class */}
          <Nav />
          <div className="flex-1">
            {" "}
            {/* Updated style */}
            <Routes>
              <Route path="/" element={<T2i />} />
              {/*<Route path="/t2i" element={<T2i />} />*/}
              <Route path="/i2i" element={<I2i />} />
              <Route path="/p2i" element={<P2i />} />
              <Route path="/ImageInformation" element={<ImageInformation />} />
              <Route path="/settings" element={<Settings />} />
            </Routes>
          </div>
        </div>
      </ThemeProvider>
    </Router>
  );
}

export default App;
