import React, { useState, useEffect } from 'react';
import { useImageGeneration } from '../context/ImageGenerationContext';
import EXIF from 'exif-js';

const ImageInformation = () => {
  const {
    imageHistory,
    handleImageClick,
    selectedImage,
    setSelectedImage,
  } = useImageGeneration();

  const [metadata, setMetadata] = useState(null);
  const [comment, setComment] = useState(null);

  const extractMetadata = (file, uint8Array) => {
    return new Promise((resolve, reject) => {
      const fileType = file.type;
      const textDecoder = new TextDecoder('utf-8');
      const metadata = {};

      if (fileType === 'image/jpeg') {
        EXIF.getData(file, function () {
          const allMetaData = EXIF.getAllTags(this);
          if (allMetaData.Comment) {
            setComment(allMetaData.Comment);
          }
          resolve(allMetaData);
        });
      } else if (fileType === 'image/png') {
        try {
          const pngSignature = new Uint8Array([137, 80, 78, 71, 13, 10, 26, 10]);
          if (!pngSignature.every((value, index) => value === uint8Array[index])) {
            throw new Error('Invalid PNG file');
          }

          let pos = 8;
          while (pos < uint8Array.length) {
            const length =
              (uint8Array[pos] << 24) |
              (uint8Array[pos + 1] << 16) |
              (uint8Array[pos + 2] << 8) |
              (uint8Array[pos + 3]);
            const chunkType = textDecoder.decode(uint8Array.subarray(pos + 4, pos + 8));
            const chunkData = uint8Array.subarray(pos + 8, pos + 8 + length);
            if (chunkType === 'tEXt' || chunkType === 'iTXt' || chunkType === 'zTXt') {
              const text = textDecoder.decode(chunkData);
              const [key, value] = text.split('\0');
              if (key === 'Comment') {
                setComment(value);
              }
              metadata[key] = value;
            }
            pos += 8 + length + 4; // Move to the next chunk
          }
          resolve(metadata);
        } catch (error) {
          reject(`Error reading PNG metadata: ${error}`);
        }
      } else if (fileType === 'image/webp') {
        try {
          const webpMetadata = extractWebPMetadata(uint8Array);
          if (webpMetadata.Comment) {
            setComment(webpMetadata.Comment);
          }
          resolve(webpMetadata);
        } catch (error) {
          reject(`Error reading WebP metadata: ${error}`);
        }
      } else {
        resolve('Unsupported file type.');
      }
    });
  };

  const extractWebPMetadata = (uint8Array) => {
    const textDecoder = new TextDecoder('utf-8');
    const metadata = {};

    const riffHeader = textDecoder.decode(uint8Array.subarray(0, 4));
    if (riffHeader !== 'RIFF') {
      throw new Error('Invalid WebP file');
    }

    const webpHeader = textDecoder.decode(uint8Array.subarray(8, 12));
    if (webpHeader !== 'WEBP') {
      throw new Error('Invalid WebP file');
    }

    let pos = 12;
    while (pos < uint8Array.length) {
      const chunkType = textDecoder.decode(uint8Array.subarray(pos, pos + 4));
      const chunkSize =
        uint8Array[pos + 4] |
        (uint8Array[pos + 5] << 8) |
        (uint8Array[pos + 6] << 16) |
        (uint8Array[pos + 7] << 24);
      const chunkData = uint8Array.subarray(pos + 8, pos + 8 + chunkSize);

      if (chunkType === 'VP8X') {
        metadata['Canvas Width'] =
          (chunkData[4] | (chunkData[5] << 8) | (chunkData[6] << 16)) + 1;
        metadata['Canvas Height'] =
          (chunkData[7] | (chunkData[8] << 8) | (chunkData[9] << 16)) + 1;
      } else if (chunkType === 'ICCP') {
        metadata['ICC Profile'] = textDecoder.decode(chunkData);
      } else if (chunkType === 'XMP ') {
        metadata['XMP Metadata'] = textDecoder.decode(chunkData);
      } else if (chunkType === 'Comment') {
        metadata['Comment'] = textDecoder.decode(chunkData);
      }

      pos += 8 + chunkSize + (chunkSize % 2); // Move to the next chunk
    }

    return metadata;
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setSelectedImage(url);

      try {
        const reader = new FileReader();
        reader.onload = async (event) => {
          const arrayBuffer = event.target.result;
          const uint8Array = new Uint8Array(arrayBuffer);

          const metadata = await extractMetadata(file, uint8Array);
          console.log('Extracted metadata from file change:', metadata);
          if (Object.keys(metadata).length === 0) {
            setMetadata(null);
            setComment(null);
          } else {
            setMetadata(metadata);
          }
        };
        reader.readAsArrayBuffer(file);
      } catch (error) {
        console.error('Error extracting metadata from file change:', error);
      }
    }
  };

  const handleHistoryImageClick = async (imgData) => {
    setSelectedImage(imgData.url);

    try {
      const response = await fetch(imgData.url);
      const blob = await response.blob();
      const arrayBuffer = await blob.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);

      const metadata = await extractMetadata(blob, uint8Array);
      console.log('Extracted metadata from history image click:', metadata);
      if (Object.keys(metadata).length === 0) {
        setMetadata(null);
        setComment(null);
      } else {
        setMetadata(metadata);
      }
    } catch (error) {
      console.error('Error extracting metadata from history image click:', error);
    }
  };

  useEffect(() => {
    if (selectedImage) {
      (async () => {
        try {
          const response = await fetch(selectedImage);
          const blob = await response.blob();
          const arrayBuffer = await blob.arrayBuffer();
          const uint8Array = new Uint8Array(arrayBuffer);

          const metadata = await extractMetadata(blob, uint8Array);
          console.log('Extracted metadata from useEffect:', metadata);
          if (Object.keys(metadata).length === 0) {
            setMetadata(null);
            setComment(null);
          } else {
            setMetadata(metadata);
          }
        } catch (error) {
          console.error('Error extracting metadata from useEffect:', error);
        }
      })();
    }
  }, [selectedImage]);

  const handleCommentClick = (value) => {
    navigator.clipboard.writeText(value).then(
      () => {
        alert('Copied to clipboard');
      },
      () => {
        alert('Failed to copy');
      }
    );
  };

  const renderComment = (comment) => {
    if (comment) {
      const commentObj = JSON.parse(comment);
      const sortedKeys = [
        'prompt',
        'uc',
        'sampler',
        'seed',
        'steps',
        'scale',
        'height',
        'width',
        'sm',
        'sm_dyn',
      ];
      const sortedComment = {};
      sortedKeys.forEach((key) => {
        if (commentObj[key]) {
          if (key === 'uc') {
            sortedComment['negative prompt'] = commentObj[key];
          } else if (key === 'sm' || key === 'sm_dyn') {
            sortedComment[key] = commentObj[key] ? 'True' : 'False';
          } else {
            sortedComment[key] = commentObj[key];
          }
        }
      });
      const remainingKeys = Object.keys(commentObj).filter(
        (key) => !sortedKeys.includes(key)
      );
      remainingKeys.forEach((key) => {
        sortedComment[key] = commentObj[key];
      });

      return Object.keys(sortedComment).map((key) => (
        <p
          key={key}
          className="bg-gray-100 dark:bg-gray-700 p-2 rounded break-words cursor-pointer"
          onClick={() =>
            key === 'prompt' || key === 'negative prompt'
              ? handleCommentClick(sortedComment[key])
              : null
          }
        >
          <strong>{key}:</strong> {sortedComment[key]}
        </p>
      ));
    }
    return <p className="text-gray-500">No EXIF available.</p>;
  };

  const copyExifData = () => {
    if (comment) {
      const commentObj = JSON.parse(comment);
      const keysToCopy = [
        'prompt',
        'uc',
        'sampler',
        'seed',
        'steps',
        'scale',
        'sm',
        'sm_dyn',
      ];
      const dataToCopy = keysToCopy
        .map((key) => {
          let value = commentObj[key];
          if (key === 'uc') {
            value = commentObj[key];
            return `negative prompt: ${value}`;
          } else if (key === 'sm' || key === 'sm_dyn') {
            value = commentObj[key];
          }
          return `${key}: ${value}`;
        })
        .join('\n');
      navigator.clipboard.writeText(dataToCopy).then(
        () => {
          alert('EXIF data copied to clipboard');
        },
        () => {
          alert('Failed to copy EXIF data');
        }
      );
    }
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-custom-white dark:bg-custom-black text-custom-purple dark:text-white p-4">
      {/* Image Information Section */}
      <div className="w-full md:w-1/4 p-4 border-custom-gray dark:border-custom-purple space-y-4 overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-bold">Image Information</h2>
        </div>
        <div className="flex justify-between items-center">
          <h3 className="text-md font-semibold mt-4">EXIF</h3>
          <button
            className="bg-custom-purple hover:bg-purple-700 dark:hover:bg-purple-500 text-white font-bold py-2 px-4 rounded"
            onClick={copyExifData}
          >
            Copy EXIF
          </button>
        </div>
        {renderComment(comment)}
      </div>
      {/* Image Preview Section */}
      <div className="relative flex-1 p-4 flex flex-col justify-center items-center border border-custom-gray dark:border-custom-purple rounded-lg">
        <div className="w-full h-full max-w-full max-h-full relative flex justify-center items-center">
          {selectedImage ? (
            <img
              src={selectedImage}
              alt="Selected"
              className="max-h-full max-w-full object-contain"
            />
          ) : (
            <p className="text-gray-500 text-center">No image selected.</p>
          )}
        </div>
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
        />
      </div>

      {/* History Section */}
      <div className="w-full md:w-1/4 p-4 overflow-auto rounded-lg relative">
        <h2 className="text-lg font-bold mb-4">History</h2>
        <div
          className="history-grid"
          style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '1rem' }}
        >
          {imageHistory.map((imgData, index) => (
            <div
              key={index}
              className="border border-custom-gray dark:border-custom-purple rounded-lg overflow-hidden relative"
              onClick={() => handleHistoryImageClick(imgData)}
            >
              <img
                src={imgData.url}
                alt={`Image ${index + 1}`}
                className="w-full h-full object-cover"
                style={{ aspectRatio: 1 }}
              />
            </div>
          ))}
        </div>
        <ins
          className="kakao_ad_area"
          style={{
            display: 'block',
            width: '100%',
            height: '250px',
            position: 'absolute',
            bottom: '0',
            overflow: 'hidden',
          }}
          data-ad-unit="DAN-ae0QcXZS9tbMG4RX"
          data-ad-width="300"
          data-ad-height="250"
        ></ins>
        <script
          type="text/javascript"
          src="//t1.daumcdn.net/kas/static/ba.min.js"
          async
        ></script>
      </div>
    </div>
  );
};

export default ImageInformation;
