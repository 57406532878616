import React, { createContext, useContext, useEffect, useState } from 'react';

const ThemeContext = createContext();

export function useTheme() {
  return useContext(ThemeContext);
}

export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState('dark'); // 초기 상태를 'dark'로 설정

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove('dark', 'light'); // 초기화
    root.classList.add(theme); // 현재 테마를 클래스에 추가
  }, [theme]);

  const toggleTheme = () => {
    setTheme(current => current === 'dark' ? 'light' : 'dark');
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}