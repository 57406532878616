import React, { useState, useEffect } from 'react';
import { useImageGeneration } from '../context/ImageGenerationContext';
import { saveAs } from 'file-saver';

const P2i = () => {
  const {
    prompt,
    setPrompt,
    negativePrompt,
    setNegativePrompt,
    loading,
    showOptions,
    setShowOptions,
    showAdvancedOptions,
    setShowAdvancedOptions,
    autoGenerator,
    setAutoGenerator,
    countdown,
    addPrePrompt,
    setAddPrePrompt,
    customWidth,
    setCustomWidth,
    customHeight,
    setCustomHeight,
    showCustomSizeSliders,
    leftCollapsed,
    setLeftCollapsed,
    rightStage,
    setRightStage,
    exifImport,
    setExifImport,
    options,
    prePrompts,
    selectedBeforePrePrompt,
    setSelectedBeforePrePrompt,
    selectedAfterPrePrompt,
    setSelectedAfterPrePrompt,
    handleGenerate,
    handleOptionChange,
    handleImageSettingChange,
    determineImageSetting,
    handleDownloadZip,
    handleDeleteHistory,
    handleImageClick,
    handleRightStageChange,
    imageHistory,
    vibeTransfers,
    addVibeTransfer,
    removeVibeTransfer,
    updateVibeTransfer,
    presets,
    setPresets,
    addPreset,
    updatePreset,
    removePreset,
    handlePresetGenerate,
    handleDownloadPresetZip,
    importPresets,
    exportPresets,
    autoSaveZip,
    setAutoSaveZip,
    handleGenerateSelectedPresets,
    convertToBase64,
    ensureBase64Prefix,
    getBlobUrlFromIndexedDB,
    deletePresetVibeTransferImage,
    removeVibeTransferFromPreset,
    saveImageToIndexedDB,
    presetGroups,
    setPresetGroups,
    currentGroupName,
    setCurrentGroupName
  } = useImageGeneration();

  const [showVibeTransfer, setShowVibeTransfer] = useState(false);
  const [collapsedPrompts, setCollapsedPrompts] = useState({});
  const [generating, setGenerating] = useState(false);
  const [allCollapsed, setAllCollapsed] = useState(true);
  const [generationDots, setGenerationDots] = useState('');
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [selectedPresets, setSelectedPresets] = useState([]);
  const [generateSelected, setGenerateSelected] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [showPresetOptions, setShowPresetOptions] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  useEffect(() => {
    let interval;
    if (generating) {
      interval = setInterval(() => {
        setGenerationDots((prev) => (prev.length < 3 ? prev + '.' : '.'));
      }, 500);
    } else {
      setGenerationDots('');
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [generating]);

  const togglePromptCollapse = (index) => {
    setCollapsedPrompts((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handlePresetGenerateClick = async () => {
    if (generating) {
      setGenerating(false);
    } else {
      setGenerating(true);
      if (generateSelected && selectedPresets.length > 0) {
        await handleGenerateSelectedPresets(selectedPresets);
      } else {
        for (let i = 0; i < presets.length; i++) {
          await handlePresetGenerate(i, i === presets.length - 1);
        }
      }
      setGenerating(false);
    }
  };

  const togglePresetSelection = (id) => {
    if (generateSelected) {
      setSelectedPresets((prev) => {
        if (prev.includes(id)) {
          return prev.filter((i) => i !== id);
        } else {
          return [...prev, id];
        }
      });
    }
  };

  const buttonClass = "bg-gray-500 hover:bg-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-200 dark:text-gray-300 font-bold py-2 px-4 rounded";

  const toggleAllPrompts = () => {
    const newCollapsedState = !allCollapsed;
    const newCollapsedPrompts = {};
    presets.forEach((_, index) => {
      newCollapsedPrompts[index] = newCollapsedState;
    });
    setCollapsedPrompts(newCollapsedPrompts);
    setAllCollapsed(newCollapsedState);
  };

  const handleImageFullscreen = (preset) => {
    setFullscreenImage(preset);
  };

  const closeFullscreenImage = () => {
    setFullscreenImage(null);
  };

  const handleDownloadPresetImage = () => {
    saveAs(fullscreenImage.image, `${fullscreenImage.name || 'preset'}.png`);
  };

  const handleRegenerateImage = async () => {
    if (fullscreenImage) {
      const index = presets.findIndex(p => p.id === fullscreenImage.id);
      if (index !== -1) {
        await handlePresetGenerate(index);
        setFullscreenImage(presets[index]);
      }
    }
  };

  const addVibeTransferToPreset = (presetIndex) => {
    updatePreset(presetIndex, 'vibeTransfers', [
      ...(presets[presetIndex].vibeTransfers || []),
      { image: null, infoExtracted: 0.5, refStrength: 0.5 },
    ]);
  };

  const handleDeleteVibeTransfer = (presetIndex, transferIndex) => {
    removeVibeTransferFromPreset(presetIndex, transferIndex);
  };

  const updateVibeTransferInPreset = async (presetIndex, transferIndex, field, value) => {
    const updatedTransfers = [...presets[presetIndex].vibeTransfers];

    if (field === 'image') {
      const file = value.target.files[0];
      const base64Image = await convertToBase64(file);
      const imageKey = await saveImageToIndexedDB(base64Image);
      updatedTransfers[transferIndex] = { ...updatedTransfers[transferIndex], image: imageKey };
    } else {
      updatedTransfers[transferIndex] = { ...updatedTransfers[transferIndex], [field]: value };
    }

    updatePreset(presetIndex, 'vibeTransfers', updatedTransfers);
  };

  const handleImportPresets = async (event) => {
    await importPresets(event);
  };

  const handleImageError = async (imageKey, e) => {
    const blobUrl = await getBlobUrlFromIndexedDB(imageKey);
    if (blobUrl) {
      e.target.src = blobUrl;
    } else {
      console.error("Image not found in IndexedDB", imageKey);
    }
  };

  const savePresetGroup = () => {
    if (newGroupName) {
      const newGroup = { name: newGroupName, presets: [...presets] };
      const updatedGroups = [...presetGroups, newGroup];
      setPresetGroups(updatedGroups);
      localStorage.setItem('presetGroups', JSON.stringify(updatedGroups));
      setNewGroupName('');
      setPresets([]); // Reset presets when saving a new group
      setCurrentGroupName(newGroupName);
      setShowSuccessPopup(true);
      setTimeout(() => setShowSuccessPopup(false), 3000); // Hide popup after 3 seconds
    }
  };

  const loadPresetGroup = (groupName) => {
    const group = presetGroups.find(group => group.name === groupName);
    if (group) {
      setPresets(group.presets);
      setCurrentGroupName(groupName);
    } else {
      setPresets([]);
      setCurrentGroupName('New preset group');
    }
  };

  const deletePresetGroup = (groupName) => {
    const updatedGroups = presetGroups.filter(group => group.name !== groupName);
    setPresetGroups(updatedGroups);
    localStorage.setItem('presetGroups', JSON.stringify(updatedGroups));
    if (groupName === currentGroupName) {
      setCurrentGroupName('New preset group');
      setPresets([]);
    }
  };

  useEffect(() => {
    if (currentGroupName === 'New preset group') {
      setPresets([]);
    }
  }, [currentGroupName]);

  return (
    <div className="flex flex-col md:flex-row h-screen p-4">
      {!leftCollapsed && (
        <div className="w-full md:w-1/4 p-4 border-custom-gray dark:border-custom-purple space-y-4 overflow-y-auto">
          <h2 className="text-lg font-bold">Prompt</h2>
          <textarea
            className="w-full h-24 p-2 mb-4 border border-gray-400 bg-gray-200 dark:border-custom-purple rounded text-black dark:text-black"
            placeholder="Enter your prompt here..."
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          ></textarea>
          <h2 className="text-lg font-bold">Negative Prompt</h2>
          <textarea
            className="w-full h-24 p-2 mb-4 border border-gray-400 bg-gray-200 dark:border-custom-purple rounded text-black dark:text-black"
            placeholder="Enter your negative prompt here..."
            value={negativePrompt}
            onChange={(e) => setNegativePrompt(e.target.value)}
          ></textarea>
          <button
            className={`w-full ${buttonClass} mt-4`}
            onClick={() => setShowOptions(!showOptions)}
          >
            {showOptions ? '▲ AI Settings' : '▼ AI Settings'}
          </button>
          {showOptions && (
            <div className="overflow-y-auto">
              <div className="mb-2">
                <label htmlFor="model" className="block">Model</label>
                <select
                  id="model"
                  value={options.model}
                  onChange={(e) => handleOptionChange('model', e.target.value)}
                  className="w-full bg-gray-200 text-black"
                >
                  <option value="nai-diffusion-3">nai-diffusion-3</option>
                  <option value="nai-diffusion-3-furry">nai-diffusion-3-furry</option>
                </select>
              </div>
              <div className="mb-2">
                <label htmlFor="sampler" className="block">Sampler</label>
                <select
                  id="sampler"
                  value={options.sampler}
                  onChange={(e) => handleOptionChange('sampler', e.target.value)}
                  className="w-full bg-gray-200 text-black"
                >
                  <option value="k_euler">Euler</option>
                  <option value="k_euler_ancestral">Euler Ancestral</option>
                  <option value="k_dpmpp_2m">DPM++ 2M</option>
                  <option value="k_dpmpp_2s_ancestral">DPM++ 2S Ancestral</option>
                  <option value="k_dpmpp_sde">DPM++ SDE</option>
                  <option value="ddim_v3">DDIM</option>
                </select>
              </div>
              <div className="mb-2">
                <label htmlFor="noise_schedule" className="block">Noise Schedule</label>
                <select
                  id="noise_schedule"
                  value={options.noise_schedule}
                  onChange={(e) => handleOptionChange('noise_schedule', e.target.value)}
                  className="w-full bg-gray-200 text-black"
                >
                  <option value="exponential">exponential</option>
                  <option value="native">native</option>
                  <option value="karras">karras</option>
                  <option value="polyexponential">polyexponential</option>
                </select>
              </div>
              <div className="mb-2 flex items-center">
                <label htmlFor="sm" className="mr-2">SMEA</label>
                <input
                  type="checkbox"
                  id="sm"
                  checked={options.sm}
                  onChange={(e) => handleOptionChange('sm', e.target.checked)}
                  className="mr-2"
                />
                <label htmlFor="sm_dyn" className="mr-2">DYN</label>
                <input
                  type="checkbox"
                  id="sm_dyn"
                  checked={options.sm_dyn}
                  onChange={(e) => handleOptionChange('sm_dyn', e.target.checked)}
                  disabled={!options.sm}
                  className="mr-2"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="image_setting" className="block">Image Setting</label>
                <select
                  id="image_setting"
                  value={determineImageSetting()}
                  onChange={(e) => handleImageSettingChange(e.target.value)}
                  className="w-full bg-gray-200 text-black"
                >
                  <option value="832x1216">Portrait (832x1216)</option>
                  <option value="1216x832">Landscape (1216x832)</option>
                  <option value="1024x1024">Square (1024x1024)</option>
                </select>
                {showOptions && showCustomSizeSliders && (
                  <>
                    <div className="mb-2">
                      <label htmlFor="customWidth" className="block">Width : {customWidth}px</label>
                      <input type="range" id="customWidth" value={customWidth} onChange={(e) => setCustomWidth(+e.target.value)}
                        className="w-full appearance-none bg-gray-200 h-2 rounded" min="1" max="1216" />
                    </div>
                    <div className="mb-2">
                      <label htmlFor="customHeight" className="block">Height : {customHeight}px</label>
                      <input type="range" id="customHeight" value={customHeight} onChange={(e) => setCustomHeight(+e.target.value)}
                        className="w-full appearance-none bg-gray-200 h-2 rounded" min="1" max="1216" />
                    </div>
                  </>
                )}
              </div>
              <div className="mb-2">
                <label htmlFor="steps" className="block">Steps : {options.steps}</label>
                <input type="range" id="steps" value={options.steps}
                  onChange={(e) => handleOptionChange('steps', +e.target.value)}
                  className="w-full" min="0" max="28" step="1" />
              </div>
              <div className="mb-2">
                <label htmlFor="scale" className="block">Scale : {options.scale}</label>
                <input type="range" id="scale" value={options.scale}
                  onChange={(e) => handleOptionChange('scale', +e.target.value)}
                  className="w-full" min="0" max="10" step="0.1" />
              </div>
              <div className="mb-2">
                <label htmlFor="cfg_rescale" className="block">Prompt Guidance Rescale : {options.cfg_rescale}</label>
                <input type="range" id="cfg_rescale" value={options.cfg_rescale}
                  onChange={(e) => handleOptionChange('cfg_rescale', +e.target.value)}
                  className="w-full" min="0" max="1" step="0.02" />
              </div>
              <div className="mb-2 flex flex-col">
                <label htmlFor="seed" className="block mb-1">Seed</label>
                <div className="flex items-center">
                  <input
                    type="text"
                    id="seed-text"
                    value={options.seed.toString()}
                    onChange={(e) => handleOptionChange('seed', Number(e.target.value))}
                    className="w-full bg-gray-200 text-black p-1 mr-2"
                    pattern="[0-9]*"
                  />
                  <input
                    type="range"
                    id="seed"
                    value={options.seed}
                    onChange={(e) => handleOptionChange('seed', +e.target.value)}
                    className="w-full"
                    min="-1"
                    max="9999999999"
                    step="1"
                  />
                </div>
              </div>
            </div>
          )}
          <button
            className={`w-full ${buttonClass} mt-4`}
            onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
          >
            {showAdvancedOptions ? '▲ Advanced Options' : '▼ Advanced Options'}
          </button>
          {showAdvancedOptions && (
            <div className="overflow-y-auto">
              <div className="mb-2">
                <label htmlFor="auto_save_zip" className="mr-2">Auto Save as Zip</label>
                <div className="relative inline-block w-10 align-middle select-none transition duration-200 ease-in bg-gray-300 rounded-full">
                  <input
                    type="checkbox"
                    id="auto_save_zip"
                    checked={autoSaveZip}
                    onChange={() => setAutoSaveZip(!autoSaveZip)}
                    className={`toggle-checkbox absolute left-1 top-1 block w-4 h-4 rounded-full border-1 appearance-none cursor-pointer ${autoSaveZip ? 'translate-x-full bg-red-600' : 'translate-x-0 bg-white'}`}
                    style={{ transition: 'transform 0.2s ease-in-out' }}
                  />
                  <label htmlFor="auto_save_zip" className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                </div>
                <label htmlFor="auto_save_zip" className={`ml-2 font-bold ${autoSaveZip ? 'text-red-600' : 'text-gray-200'}`}>{autoSaveZip ? 'On' : 'Off'}</label>
              </div>
            </div>
          )}
          <button
            className={`w-full ${buttonClass} mt-4`}
            onClick={() => setShowPresetOptions(!showPresetOptions)}
          >
            {showPresetOptions ? '▲ Preset Options' : '▼ Preset Options'}
          </button>
          {showPresetOptions && (
            <div className="overflow-y-auto">
              <div className="mt-4">
                <h2 className="text-lg font-bold">Preset Groups</h2>
                <div className="mb-4">
                  <select
                    className="w-full bg-gray-200 text-black p-2 rounded"
                    value={currentGroupName}
                    onChange={(e) => loadPresetGroup(e.target.value)}
                  >
                    <option value="New preset group">New preset group...</option>
                    {presetGroups.map((group, index) => (
                      <option key={index} value={group.name}>{group.name}</option>
                    ))}
                  </select>
                </div>
                {currentGroupName === 'New preset group' && (
                  <div className="flex mb-4">
                    <input
                      type="text"
                      className="flex-1 bg-gray-200 text-black p-2 rounded mr-2"
                      placeholder="New Group Name"
                      value={newGroupName}
                      onChange={(e) => setNewGroupName(e.target.value)}
                    />
                    <button
                      className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
                      onClick={savePresetGroup}
                    >
                      <strong>+</strong>
                    </button>
                  </div>
                )}
              </div>
              <button
                className={`w-full bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded mt-4`}
                onClick={addPreset}
              >
                + Add Preset
              </button>
              <div className="mt-4 flex space-x-2">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  onClick={exportPresets}
                >
                  Export Presets
                </button>
                <input
                  type="file"
                  id="import-presets"
                  className="hidden"
                  onChange={handleImportPresets}
                />
                <label
                  htmlFor="import-presets"
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded text-center cursor-pointer"
                >
                  Import Presets
                </label>
                {currentGroupName !== 'New preset group' && (
                  <button
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => deletePresetGroup(currentGroupName)}
                  >
                    Delete Group
                  </button>
                )}
              </div>
            </div>
          )}
          <button
            className={`w-full ${buttonClass} mt-4`}
            onClick={() => setShowVibeTransfer(!showVibeTransfer)}
          >
            {showVibeTransfer ? '▲ Vibe Transfer' : '▼ Vibe Transfer'}
          </button>
          {showVibeTransfer && (
            <div className="overflow-y-auto">
              {vibeTransfers.map((vibeTransfer, index) => (
                <div key={index} className="mb-4 p-4 border border-gray-400 rounded-lg flex">
                  <div className="relative flex-shrink-0 mr-4">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={async (e) => {
                        const file = e.target.files[0];
                        const base64Image = await convertToBase64(file);
                        updateVibeTransfer(index, 'image', ensureBase64Prefix(base64Image));
                      }}
                      className="absolute inset-0 w-full h-full opacity-0 cursor-pointer z-10"
                    />
                    <div className="relative w-24 h-24 bg-gray-200 overflow-hidden flex items-center justify-center">
                      {vibeTransfer.image ? (
                        <img
                          src={ensureBase64Prefix(vibeTransfer.image)}
                          alt="Vibe Transfer"
                          className="absolute inset-0 w-full h-full object-contain"
                          onError={(e) => handleImageError(vibeTransfer.image, e)}
                        />
                      ) : (
                        <span className="text-gray-500">+</span>
                      )}
                      <button
                        className="absolute top-0 right-0 bg-red-500 text-white p-1 z-20"
                        onClick={() => removeVibeTransfer(index)}
                      >
                        ✕
                      </button>
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="mb-2">
                      <label htmlFor={`info_extracted_${index}`} className="block">Information Extracted: {vibeTransfer.infoExtracted}</label>
                      <input
                        type="range"
                        id={`info_extracted_${index}`}
                        value={vibeTransfer.infoExtracted}
                        onChange={(e) => updateVibeTransfer(index, 'infoExtracted', +e.target.value)}
                        className="w-full"
                        min="0.01"
                        max="1"
                        step="0.01"
                      />
                    </div>
                    <div className="mb-2">
                      <label htmlFor={`ref_strength_${index}`} className="block">Reference Strength: {vibeTransfer.refStrength}</label>
                      <input
                        type="range"
                        id={`ref_strength_${index}`}
                        value={vibeTransfer.refStrength}
                        onChange={(e) => updateVibeTransfer(index, 'refStrength', +e.target.value)}
                        className="w-full"
                        min="0.01"
                        max="1"
                        step="0.01"
                      />
                    </div>
                  </div>
                </div>
              ))}
              <button
                className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
                onClick={addVibeTransfer}
              >
                +
              </button>
            </div>
          )}
        </div>
      )}
      <div className={`relative flex-1 p-4 flex flex-col items-start border border-custom-gray dark:border-custom-purple rounded-lg ${leftCollapsed ? 'w-full md:w-3/4' : 'w-full md:w-1/2'}`}>
        <div className="flex flex-col w-full items-start mb-4">
          <div className="flex items-center w-full">
            <button
              className="bg-gray-500 hover:bg-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600 text-white font-bold py-1 px-4 rounded"
              onClick={toggleAllPrompts}
            >
              {allCollapsed ? 'Expand All' : 'Collapse All'}
            </button>
            <button
              className={`bg-gray-500 hover:bg-gray-400 text-white font-bold py-1 px-4 rounded ml-4`}
              onClick={() => setGenerateSelected(!generateSelected)}
            >
              {generateSelected ? 'Generate Selected: On' : 'Generate Selected: Off'}
            </button>
            <button
              className={`bg-gray-500 hover:bg-gray-400 text-white font-bold py-1 px-4 rounded ml-4`}
              onClick={handleDownloadPresetZip}
            >
              Download Zip
            </button>
          </div>
        </div>
        <div className="w-full h-full max-w-full max-h-full relative flex flex-col items-start overflow-y-auto">
          {presets.length ? (
            <div className="flex flex-wrap w-full">
              {presets.map((preset, index) => (
                <div
                  key={preset.id}
                  className={`mb-4 p-4 border border-gray-400 rounded-lg w-full md:w-1/4 cursor-pointer ${generateSelected && selectedPresets.includes(preset.id) ? 'bg-gray-300 dark:bg-gray-700' : ''}`}
                  onClick={() => togglePresetSelection(preset.id)}
                >
                  <div className="flex justify-between items-center mb-2">
                    <input
                      type="text"
                      className="w-2/3 bg-gray-200 text-black p-2 rounded"
                      placeholder="Preset Name"
                      value={preset.name || ''}
                      onChange={(e) => updatePreset(index, 'name', e.target.value)}
                    />
                    <button
                      className="bg-gray-500 text-white p-1 rounded"
                      onClick={(e) => { e.stopPropagation(); handlePresetGenerate(index); }}
                      disabled={loading}
                    >
                      <strong>↻</strong>
                    </button>
                    <button
                      className="bg-gray-500 text-white p-1 rounded mr-2"
                      onClick={(e) => { e.stopPropagation(); togglePromptCollapse(index); }}
                    >
                      ▼
                    </button>
                    <button className="bg-red-500 text-white p-1 rounded" onClick={(e) => { e.stopPropagation(); removePreset(index); }}>✕</button>
                  </div>
                  <div
                    className="relative w-full h-64 bg-gray-800 overflow-hidden flex items-center justify-center mb-2 border border-gray-400 rounded-lg"
                    onClick={(e) => { e.stopPropagation(); handleImageFullscreen(preset); }}
                  >
                    {preset.image ? (
                      <img
                        src={ensureBase64Prefix(preset.image)}
                        alt={`Preset ${index + 1}`}
                        className="absolute inset-0 w-full h-full object-contain"
                        onError={(e) => handleImageError(preset.imageKey, e)}
                      />
                    ) : (
                      <span className="text-gray-500">No image generated yet.</span>
                    )}
                  </div>
                  {!collapsedPrompts[index] && (
                    <>
                      <div className="mb-2">
                        <label htmlFor={`before_pre_prompt_${index}`} className="block">Before Pre-Prompt</label>
                        <select
                          id={`before_pre_prompt_${index}`}
                          value={preset.beforePrePrompt || ''}
                          onChange={(e) => updatePreset(index, 'beforePrePrompt', e.target.value)}
                          className="w-full bg-gray-200 text-black"
                        >
                          <option value="">Select a Pre-Prompt</option>
                          {prePrompts.filter(p => p.position === 0).map(prePrompt => (
                            <option key={prePrompt.name} value={prePrompt.name}>
                              {prePrompt.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-2">
                        <label htmlFor={`preset_prompt_${index}`} className="block">Preset Prompt</label>
                        <textarea
                          id={`preset_prompt_${index}`}
                          className="w-full h-16 p-2 mb-2 border border-gray-400 bg-gray-200 rounded text-black"
                          placeholder="Enter preset prompt..."
                          value={preset.presetPrompt}
                          onChange={(e) => updatePreset(index, 'presetPrompt', e.target.value)}
                        ></textarea>
                      </div>
                      <div className="mb-2">
                        <label htmlFor={`after_pre_prompt_${index}`} className="block">After Pre-Prompt</label>
                        <select
                          id={`after_pre_prompt_${index}`}
                          value={preset.afterPrePrompt || ''}
                          onChange={(e) => updatePreset(index, 'afterPrePrompt', e.target.value)}
                          className="w-full bg-gray-200 text-black"
                        >
                          <option value="">Select a Pre-Prompt</option>
                          {prePrompts.filter(p => p.position === 1).map(prePrompt => (
                            <option key={prePrompt.name} value={prePrompt.name}>
                              {prePrompt.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <button
                        className={`w-full ${buttonClass} mt-4`}
                        onClick={(e) => { e.stopPropagation(); preset.showVibeTransfer = !preset.showVibeTransfer; updatePreset(index, 'showVibeTransfer', preset.showVibeTransfer); }}
                      >
                        {preset.showVibeTransfer ? '▲ Vibe Transfer' : '▼ Vibe Transfer'}
                      </button>
                      {preset.showVibeTransfer && (
                        <div className="overflow-y-auto">
                          {preset.vibeTransfers && preset.vibeTransfers.map((transfer, transferIndex) => (
                            <div key={transferIndex} className="mb-4 p-4 border border-gray-400 rounded-lg flex">
                              <div className="relative flex-shrink-0 mr-4">
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={async (e) => updateVibeTransferInPreset(index, transferIndex, 'image', e)}
                                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer z-10"
                                />
                                <div className="relative w-24 h-24 bg-gray-200 overflow-hidden flex items-center justify-center">
                                  {transfer.image ? (
                                    <img
                                      src={ensureBase64Prefix(transfer.image)}
                                      alt="Vibe Transfer"
                                      className="absolute inset-0 w-full h-full object-contain"
                                      onError={(e) => handleImageError(transfer.image, e)}
                                    />
                                  ) : (
                                    <span className="text-gray-500">+</span>
                                  )}
                                  <button
                                    className="absolute top-0 right-0 bg-red-500 text-white p-1 z-20"
                                    onClick={(e) => { e.stopPropagation(); handleDeleteVibeTransfer(index, transferIndex); }}
                                  >
                                    ✕
                                  </button>
                                </div>
                              </div>
                              <div className="flex-1">
                                <div className="mb-2">
                                  <label htmlFor={`info_extracted_${index}_${transferIndex}`} className="block">Information Extracted: {transfer.infoExtracted}</label>
                                  <input
                                    type="range"
                                    id={`info_extracted_${index}_${transferIndex}`}
                                    value={transfer.infoExtracted}
                                    onChange={(e) => updateVibeTransferInPreset(index, transferIndex, 'infoExtracted', +e.target.value)}
                                    className="w-full"
                                    min="0.01"
                                    max="1"
                                    step="0.01"
                                  />
                                </div>
                                <div className="mb-2">
                                  <label htmlFor={`ref_strength_${index}_${transferIndex}`} className="block">Reference Strength: {transfer.refStrength}</label>
                                  <input
                                    type="range"
                                    id={`ref_strength_${index}_${transferIndex}`}
                                    value={transfer.refStrength}
                                    onChange={(e) => updateVibeTransferInPreset(index, transferIndex, 'refStrength', +e.target.value)}
                                    className="w-full"
                                    min="0.01"
                                    max="1"
                                    step="0.01"
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                          <button
                            className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
                            onClick={(e) => { e.stopPropagation(); addVibeTransferToPreset(index); }}
                          >
                            +
                          </button>
                        </div>
                      )}
                    </>
                  )}
                  <div className="kakao-ad-container">
                    <ins className="kakao_ad_area" style={{ display: 'none' }}
                      data-ad-unit="DAN-ae0QcXZS9tbMG4RX"
                      data-ad-width="300"
                      data-ad-height="250"></ins>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-500 text-center">No presets available.</p>
          )}
        </div>
        <button
          className={`fixed bottom-4 left-1/2 transform -translate-x-1/2 ${autoGenerator ? 'bg-green-500' : 'bg-custom-purple hover:bg-purple-700'} dark:hover:bg-purple-500 text-white font-bold py-3 px-6 rounded`}
          onClick={handlePresetGenerateClick}
          disabled={loading}
          style={{ zIndex: 1000 }}
        >
          {loading ? (
            <img src="/img/loading.webp" alt="loading" className="w-6 h-6 mx-auto" />
          ) : (
            generating ? `Stop Generating ${generationDots}` : `Preset Generate (${generateSelected ? selectedPresets.length : presets.length})`
          )}
        </button>
        <button
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-500 hover:bg-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600 text-white font-bold py-2 px-2 rounded"
          onClick={() => setLeftCollapsed(!leftCollapsed)}
        >
          {leftCollapsed ? (
            <>
              <span className="block md:hidden">▼</span>
              <span className="hidden md:block">▶</span>
            </>
          ) : (
            <>
              <span className="block md:hidden">▲</span>
              <span className="hidden md:block">◀</span>
            </>
          )}
        </button>
      </div>
      {fullscreenImage && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
          <div className="relative bg-gray-200 dark:bg-gray-500 p-4 rounded-lg w-3/4 h-4/5 overflow-auto text-center">
            <div className="relative w-full h-4/5 mb-4">
              <img src={ensureBase64Prefix(fullscreenImage.image)} alt={fullscreenImage.name} className="w-full h-full object-contain" />
            </div>
            <div className="p-4 border rounded-lg">
              <p className="text-xl font-bold">{fullscreenImage.name}</p>
              <p className="mt-2">{fullscreenImage.presetPrompt}</p>
              <div className="flex justify-center space-x-4 mt-4">
                <button
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded text-center cursor-pointer"
                  onClick={handleDownloadPresetImage}
                >
                  <strong>↓</strong>
                </button>
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-center cursor-pointer"
                  onClick={handleRegenerateImage}
                >
                  <strong>↻</strong>
                </button>
              </div>
            </div>
            <button
              className="absolute top-2 right-2 text-white bg-red-500 hover:bg-red-700 p-2 rounded-full"
              onClick={closeFullscreenImage}
            >
              ✕
            </button>
          </div>
        </div>
      )}
      {showSuccessPopup && (
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-green-500 text-white py-2 px-4 rounded-lg">
          Preset group saved successfully!
        </div>
      )}
    </div>
  );
};

export default P2i;
