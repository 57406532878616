const DB_NAME = 'ImageGenerationDB';
const DB_VERSION = 1;
export const STORE_NAME = 'images';

let db;

export const openDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);
    request.onerror = (event) => {
      console.error('Error opening IndexedDB:', event.target.errorCode);
      reject(event.target.errorCode);
    };
    request.onsuccess = (event) => {
      db = event.target.result;
      resolve(db);
    };
    request.onupgradeneeded = (event) => {
      db = event.target.result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'id' });
      }
    };
  });
};

const getDBConnection = async () => {
  if (!db) {
    db = await openDB();
  }
  return db;
};

export const saveImage = async (id, imageData) => {
  const db = await getDBConnection();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.put({ id, imageData });
    request.onsuccess = () => resolve();
    request.onerror = (event) => reject(event.target.errorCode);
  });
};

export const getImage = async (id) => {
  const db = await getDBConnection();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readonly');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.get(id);
    request.onsuccess = (event) => {
      const result = event.target.result;
      if (result && result.imageData) {
        resolve(result.imageData);
      } else {
        resolve(null);
      }
    };
    request.onerror = (event) => reject(event.target.errorCode);
  });
};

export const deleteImage = async (id) => {
  const db = await getDBConnection();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.delete(id);
    request.onsuccess = () => resolve();
    request.onerror = (event) => reject(event.target.errorCode);
  });
};

export const clearDB = async () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.deleteDatabase(DB_NAME);
    request.onsuccess = () => {
      console.log('Database deleted successfully');
      resolve();
    };
    request.onerror = (event) => {
      console.error('Error deleting database:', event.target.errorCode);
      reject(event.target.errorCode);
    };
  });
};