import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../context/ThemeContext';

function Nav() {
  const [isOpen, setIsOpen] = useState(false);
  const { theme, toggleTheme } = useTheme();

  const toggleMenu = () => setIsOpen(!isOpen);
  const menuButtonLabel = isOpen ? 'Close' : 'Menu';
  const buttonStyle = isOpen ? "bg-red-500 hover:bg-red-700" : "bg-custom-purple hover:bg-purple-700";
  const hoverEffect = "transition duration-150 ease-in-out";
  const linkBgHover = "hover:bg-opacity-80 hover:bg-custom-purple"; // Adjust this line to control hover background color
  const linkStyle = `text-custom-purple dark:text-white py-2 ${hoverEffect} ${linkBgHover}`;

  const bgClassName = theme === 'dark' ? "bg-opacity-50 bg-black" : "bg-opacity-100 bg-white";

  return (
    <div className={`fixed top-4 right-4 z-50 ${bgClassName}`}>
      <button
        onClick={toggleMenu}
        className={`text-white font-bold px-4 py-2 rounded ${buttonStyle} ${hoverEffect}`}
      >
        {menuButtonLabel}
      </button>
      {isOpen && (
        <div className="flex flex-col items-center mt-2 font-bold bg-white dark:bg-black p-2 rounded shadow">
          {/*<Link to="/" onClick={toggleMenu} className={linkStyle}>Home</Link> */}
          <Link to="/" onClick={toggleMenu} className={linkStyle}>Text to Image</Link>
          {/* <Link to="/i2i" onClick={toggleMenu} className={linkStyle}>Image to Image</Link> */}
          <Link to="/p2i" onClick={toggleMenu} className={linkStyle}>Preset to Image</Link>
          <Link to="/ImageInformation" onClick={toggleMenu} className={linkStyle}>Image Information</Link>
          <Link to="/settings" onClick={toggleMenu} className={linkStyle}>Settings</Link>
          <button 
            onClick={toggleTheme} 
            className={`px-4 py-2 rounded text-custom-purple dark:text-white mt-4 ${hoverEffect}`}
          >
            {theme === 'dark' ? 'Light Mode' : 'Dark Mode'}
          </button>
        </div>
      )}
    </div>
  );
}

export default Nav;
