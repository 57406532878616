import React, { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import { clearDB, openDB, STORE_NAME } from '../context/indexedDB'; // Import clearDB and openDB functions

const Settings = () => {
  const [showSystemSettings, setShowSystemSettings] = useState(false);
  const [showAdvancedSettings, setshowAdvancedSettings] = useState(false);
  const [showPrePromptSettings, setShowPrePromptSettings] = useState(false);
  const [selectedUrlOption, setSelectedUrlOption] = useState('Custom');
  const [requestUrl, setRequestUrl] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [isApiKeyVisible, setIsApiKeyVisible] = useState(false);
  const [playAlertOnCompletion, setPlayAlertOnCompletion] = useState(false);
  const [autoGenerateDelay, setAutoGenerateDelay] = useState(15);
  const [presetGenerateDelay, setPresetGenerateDelay] = useState(15);
  const [showSavePopup, setShowSavePopup] = useState(false);
  const [showClearPopup, setShowClearPopup] = useState(false);
  const [prePrompts, setPrePrompts] = useState([]);
  const [prePromptName, setPrePromptName] = useState('');
  const [prePromptText, setPrePromptText] = useState('');
  const [prePromptPosition, setPrePromptPosition] = useState('before');

  useEffect(() => {
    const savedUrl = localStorage.getItem('requestUrl');
    const savedApiKey = localStorage.getItem('apiKey');
    const savedAlert = localStorage.getItem('playAlertOnCompletion');
    const savedAutoGenerateDelay = localStorage.getItem('autoGenerateDelay');
    const savedPresetGenerateDelay = localStorage.getItem('presetGenerateDelay');
    const savedPrePrompts = localStorage.getItem('prePrompts');

    if (savedUrl) {
      setRequestUrl(savedUrl);
      setSelectedUrlOption(savedUrl === 'https://image.novelai.net/ai/generate-image' ? 'NovelAI' : 'Custom');
    }
    if (savedApiKey) setApiKey(decryptApiKey(savedApiKey));
    if (savedAlert) setPlayAlertOnCompletion(savedAlert === 'true');
    if (savedAutoGenerateDelay) setAutoGenerateDelay(Number(savedAutoGenerateDelay));
    if (savedPresetGenerateDelay) setPresetGenerateDelay(Number(savedPresetGenerateDelay));
    if (savedPrePrompts) setPrePrompts(JSON.parse(savedPrePrompts));
  }, []);

  const encryptApiKey = (key) => CryptoJS.AES.encrypt(key, process.env.REACT_APP_SECRET).toString();
  const decryptApiKey = (ciphertext) => {
    try {
      const bytes = CryptoJS.AES.decrypt(ciphertext, process.env.REACT_APP_SECRET);
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error("Failed to decrypt API key", error);
      return '';
    }
  };

  const handleSave = () => {
    const minDelay = 15;
    const adjustedAutoGenerateDelay = autoGenerateDelay < minDelay ? minDelay : autoGenerateDelay;
    const adjustedPresetGenerateDelay = presetGenerateDelay < minDelay ? minDelay : presetGenerateDelay;

    localStorage.setItem('requestUrl', requestUrl);
    localStorage.setItem('apiKey', encryptApiKey(apiKey));
    localStorage.setItem('playAlertOnCompletion', playAlertOnCompletion);
    localStorage.setItem('autoGenerateDelay', adjustedAutoGenerateDelay);
    localStorage.setItem('presetGenerateDelay', adjustedPresetGenerateDelay);
    localStorage.setItem('prePrompts', JSON.stringify(prePrompts));

    setAutoGenerateDelay(adjustedAutoGenerateDelay);
    setPresetGenerateDelay(adjustedPresetGenerateDelay);

    setShowSavePopup(true);
    setTimeout(() => setShowSavePopup(false), 3000);
  };

  const handleClear = async () => {
    // Close the popup first
    setShowClearPopup(false);

    // Clear local storage and reset state
    localStorage.clear();
    setRequestUrl('');
    setApiKey('');
    setPlayAlertOnCompletion(false);
    setAutoGenerateDelay(15);
    setPresetGenerateDelay(15);
    setPrePrompts([]);

    // Clear IndexedDB
    await clearDB();
  };

  const addPrePrompt = () => {
    if (prePromptName && prePromptText) {
      const positionValue = prePromptPosition === 'before' ? 0 : 1;
      const newPrePrompts = [...prePrompts, { name: prePromptName, text: prePromptText, position: positionValue }];
      setPrePrompts(newPrePrompts);
      localStorage.setItem('prePrompts', JSON.stringify(newPrePrompts));
      setPrePromptName('');
      setPrePromptText('');
    }
  };

  const deletePrePrompt = (index) => {
    const newPrePrompts = [...prePrompts];
    newPrePrompts.splice(index, 1);
    setPrePrompts(newPrePrompts);
    localStorage.setItem('prePrompts', JSON.stringify(newPrePrompts));
  };

  const editPrePrompt = (index) => {
    const prePrompt = prePrompts[index];
    setPrePromptName(prePrompt.name);
    setPrePromptText(prePrompt.text);
    setPrePromptPosition(prePrompt.position === 0 ? 'before' : 'after');
    deletePrePrompt(index);
  };

  const downloadPrePrompts = () => {
    const element = document.createElement("a");
    const file = new Blob([JSON.stringify(prePrompts, null, 2)], { type: 'application/json' });
    element.href = URL.createObjectURL(file);
    element.download = "prePrompts.json";
    document.body.appendChild(element);
    element.click();
  };

  const importPrePrompts = (event) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const importedPrePrompts = JSON.parse(e.target.result);
      const updatedPrePrompts = [...prePrompts, ...importedPrePrompts];
      setPrePrompts(updatedPrePrompts);
      localStorage.setItem('prePrompts', JSON.stringify(updatedPrePrompts));
    };
    fileReader.readAsText(event.target.files[0]);
  };

  const getDelayRange = () => selectedUrlOption === 'NovelAI' ? { min: 15, max: 180 } : { min: 15, max: 180 };

  const handleUrlChange = (value) => {
    setSelectedUrlOption(value);
    if (value === 'NovelAI') {
      setRequestUrl('https://image.novelai.net/ai/generate-image');
    } else {
      setRequestUrl('');
      setAutoGenerateDelay(15);
      setPresetGenerateDelay(15);
    }
  };

  const handleAutoGenerateDelayChange = (value) => {
    const minDelay = 15;
    setAutoGenerateDelay(value < minDelay ? minDelay : value);
  };

  const handlePresetGenerateDelayChange = (value) => {
    const minDelay = 15;
    setPresetGenerateDelay(value < minDelay ? minDelay : value);
  };

  return (
    <div className="flex flex-col items-center h-screen bg-custom-white dark:bg-custom-black text-custom-purple dark:text-white p-4 overflow-hidden">
      <div className="w-full max-w-4xl p-4 border border-custom-gray dark:border-custom-purple rounded-lg overflow-y-auto h-full space-y-4">
        {showSavePopup && (
          <div className="absolute top-20 left-1/2 transform -translate-x-1/2 bg-gray-300 border border-gray-300 p-4 rounded shadow-lg text-custom-purple">
            Settings Saved!
          </div>
        )}
        {showClearPopup && (
          <div className="absolute top-20 left-1/2 transform -translate-x-1/2 bg-red-300 border border-red-300 p-4 rounded shadow-lg text-custom-purple">
            <div>Are you sure you want to clear all settings?</div>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowClearPopup(false)}
                className="bg-gray-500 hover:bg-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600 text-white font-bold py-2 px-4 rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handleClear}
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
              >
                Clear
              </button>
            </div>
          </div>
        )}
        <section>
          <div className="font-bold py-2 px-4">Settings</div>
          <button
            className={`w-full ${showSystemSettings ? 'bg-red-900 hover:bg-red-800' : 'bg-gray-500 hover:bg-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600'} text-gray-200 dark:text-gray-300 font-bold py-2 px-4 rounded mt-4`}
            onClick={() => setShowSystemSettings(!showSystemSettings)}
          >
            {showSystemSettings ? '▲ System Settings' : '▼ System Settings'}
          </button>
          {showSystemSettings && (
            <div className="mt-4">
              <label htmlFor="url">Request URL:</label>
              <select
                id="url"
                value={selectedUrlOption}
                onChange={(e) => handleUrlChange(e.target.value)}
                className="w-full p-2 border border-gray-300 bg-gray-200 rounded text-black"
              >
                <option value="NovelAI">NovelAI</option>
                <option value="Custom">Custom</option>
              </select>
              {selectedUrlOption === 'Custom' && (
                <input
                  type="text"
                  value={requestUrl}
                  onChange={e => setRequestUrl(e.target.value)}
                  className="w-full p-2 border border-gray-300 bg-gray-200 rounded text-black mt-2"
                />
              )}
              <label htmlFor="apiKey">API Key:</label>
              <input
                type={isApiKeyVisible ? "text" : "password"}
                id="apiKey"
                value={apiKey}
                onChange={e => setApiKey(e.target.value)}
                className="w-full p-2 border border-gray-300 bg-gray-200 rounded text-black mt-2"
              />
              <button
                onClick={() => setIsApiKeyVisible(!isApiKeyVisible)}
                className="text-blue-500 mt-2"
              >
                {isApiKeyVisible ? "Hide" : "Show"} API Key
              </button>
            </div>
          )}
        </section>
        <section>
          <button
            className={`w-full ${showAdvancedSettings ? 'bg-red-900 hover:bg-red-800' : 'bg-gray-500 hover:bg-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600'} text-gray-200 dark:text-gray-300 font-bold py-2 px-4 rounded mt-4`}
            onClick={() => setshowAdvancedSettings(!showAdvancedSettings)}
          >
            {showAdvancedSettings ? '▲ Advanced Settings' : '▼ Advanced Settings'}
          </button>
          {showAdvancedSettings && (
            <div className="mt-4">
              <div className="flex items-center mb-4">
                <input
                  type="checkbox"
                  id="alertCompletion"
                  checked={playAlertOnCompletion}
                  onChange={() => setPlayAlertOnCompletion(!playAlertOnCompletion)}
                  className="mr-2"
                />
                <label htmlFor="alertCompletion">Play alert on completion</label>
              </div>
              <div className="mb-4">
                <label htmlFor="autoGenerateDelay">Auto-Generate Delay ({getDelayRange().min}-{getDelayRange().max} seconds): {autoGenerateDelay}</label>
                <input
                  type="range"
                  min={getDelayRange().min}
                  max={getDelayRange().max}
                  value={autoGenerateDelay}
                  onChange={e => handleAutoGenerateDelayChange(Number(e.target.value))}
                  className="w-full"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="presetGenerateDelay">Preset Generate Delay ({getDelayRange().min}-{getDelayRange().max} seconds): {presetGenerateDelay}</label>
                <input
                  type="range"
                  min={getDelayRange().min}
                  max={getDelayRange().max}
                  value={presetGenerateDelay}
                  onChange={e => handlePresetGenerateDelayChange(Number(e.target.value))}
                  className="w-full"
                />
              </div>
            </div>
          )}
        </section>
        <section>
          <button
            className={`w-full ${showPrePromptSettings ? 'bg-red-900 hover:bg-red-800' : 'bg-gray-500 hover:bg-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600'} text-gray-200 dark:text-gray-300 font-bold py-2 px-4 rounded mt-4`}
            onClick={() => setShowPrePromptSettings(!showPrePromptSettings)}
          >
            {showPrePromptSettings ? '▲ Pre-Prompt Settings' : '▼ Pre-Prompt Settings'}
          </button>
          {showPrePromptSettings && (
            <div className="mt-4">
              <div className="flex items-center">
                <input
                  type="text"
                  value={prePromptName}
                  onChange={(e) => setPrePromptName(e.target.value)}
                  placeholder="Pre-Prompt Name"
                  className="w-full p-2 border border-gray-300 bg-gray-200 rounded text-black mr-2"
                />
                <select
                  value={prePromptPosition}
                  onChange={(e) => setPrePromptPosition(e.target.value)}
                  className="w-1/4 p-2 border border-gray-300 bg-gray-200 rounded text-black"
                >
                  <option value="before">Before</option>
                  <option value="after">After</option>
                </select>
              </div>
              <div className="mt-2">
                <textarea
                  className="w-full h-24 p-2 mb-4 border border-gray-400 bg-gray-200 dark:border-custom-purple rounded text-black dark:text-black"
                  placeholder="Enter your pre-prompt text here..."
                  value={prePromptText}
                  onChange={(e) => setPrePromptText(e.target.value)}
                ></textarea>
              </div>
              <div className="flex justify-end mb-4">
                <button
                  onClick={addPrePrompt}
                  className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
                >
                  +
                </button>
              </div>
              {prePrompts.map((prePrompt, index) => (
                <div key={index} className="flex items-center mt-2 border-b border-gray-300 pb-2 mb-2">
                  <div className="flex-1 p-2 border border-gray-300 bg-gray-200 rounded text-black">
                    {prePrompt.name} ({prePrompt.position === 0 ? 'before' : 'after'})
                  </div>
                  <button
                    onClick={() => editPrePrompt(index)}
                    className="bg-gray-500 hover:bg-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600 text-white font-bold py-2 px-4 rounded ml-2"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => deletePrePrompt(index)}
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded ml-2"
                  >
                    -
                  </button>
                </div>
              ))}
              <div className="flex justify-end mt-4">
                <input
                  type="file"
                  accept="application/json"
                  onChange={importPrePrompts}
                  className="hidden"
                  id="upload-json"
                />
                <label
                  htmlFor="upload-json"
                  className="bg-custom-purple hover:bg-purple-700 dark:hover:bg-purple-500 text-white font-bold py-2 px-4 rounded cursor-pointer mr-2"
                >
                  Import
                </label>
                <button
                  onClick={downloadPrePrompts}
                  className="bg-custom-purple hover:bg-purple-700 dark:hover:bg-purple-500 text-white font-bold py-2 px-4 rounded"
                >
                  Export
                </button>
              </div>
            </div>
          )}
        </section>
        <div className="flex justify-end space-x-4">
          <button
            className="bg-custom-purple hover:bg-purple-700 dark:hover:bg-purple-500 text-white font-bold py-2 px-4 rounded"
            onClick={handleSave}
          >
            Save All Settings
          </button>
          <button
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
            onClick={() => setShowClearPopup(true)}
          >
            Clear All Settings
          </button>
        </div>
      </div>
      <div className="w-full mt-4">
        <ins className="kakao_ad_area"
          style={{ display: 'block' }}
          data-ad-unit="DAN-pe3RxiawCPk7QumI"
          data-ad-width="728"
          data-ad-height="90"></ins>
        <script type="text/javascript" src="//t1.daumcdn.net/kas/static/ba.min.js" async></script>
      </div>
    </div>
  );
};

export default Settings;
